import React from 'react'
import './reviewscontainer.styles.scss'
import { Rating } from '..'
import { PiUserCheck } from 'react-icons/pi'
import { formatDistance, subDays } from 'date-fns'

const ReviewsContainer = ({data}) => {
  return (
    <div className='reviews-container'>
        {/* user image and information container */}
        <div className='d-flex items-center'>
            {/* image */}
            <img src={data.fromImage} alt={data.user} />
            {/* user name, rating, date */}
            <div className='d-flex column px-10'>
                {/* guide name and icon container */}
                <div className='d-flex'>
                    {/* guide name */}
                    <p className='guide-name'>{data?.fromUser?.fullName}</p>
                    {/* icon */}
                    <p><PiUserCheck size={20} color='var(--primary)'/></p>
                </div>
                <div className='d-flex ratings-container'>
                    <Rating rating={data?.overallRating}/>
                </div>
                <p className='date'>{formatDistance(subDays(data?.createdAt, 0), new Date(), { addSuffix: true })}</p>
            </div>
        </div>
        {/* feedback text from user */}
        <div className='d-flex flex-wrap items-center space-between'>
            <p className='desc-text'>
                {data?.review}
            </p>
            {data?.image &&
                <img onClick={()=>window.open(data?.image)} style={{margin:"10px 0px", height:40, border:'1px solid gray', width:40, borderRadius:0}} src={data?.image} alt='' />
            }
        </div>
        <hr />
    </div>
  )
}

export default ReviewsContainer