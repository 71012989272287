import React, { useContext, useState } from 'react'
import '../UpdateUsername/updateusername.styles.scss'
import { StateContext } from '../../context/stateContext'
import { BackButton, ButtonComponent, OtpComponent } from '../../components'
import { updateContact } from '../../utils/service'
import { notify } from '../../components/Toast'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UpdateUsername = () => {
    const {userInfo} = useContext(StateContext)
    const [contact, setContact] = useState('')
    const [showUpdate, setShowUpdate] = useState(false)
    const [state, setState] = useState({
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:''
    })

    const updateHandler = ()=>{
        if(contact.length < 5){
            notify('please enter a valid phone number')
        }
        updateContact(contact).then(res => {
            notify('Contact updated')
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

  return (
    <div className='w-100'>
        <BackButton size={24} text='Update Contact' />
        <div className='input-container input-container-uc update-username-container w-75'>
          <span className='label'>Current Contact</span>
          <input style={{width:'75%'}} value={userInfo?.contact} disabled />
        </div>
        <div className='px-20 w-75'>
            <PhoneInput
                country={'in'}
                value={contact}
                onChange={phone => setContact(phone)}
            />
        </div>

        {/* Get Otp button */}
        <ButtonComponent onClick={() => updateHandler()} title='Get OTP' wrapperContainerStyle={{margin:"10px 20px"}}/>

        {/* otp boxes */}
        <OtpComponent state={state} setState={setState}/>

        <ButtonComponent onClick={showUpdate ? () => updateHandler() : () => setShowUpdate(true) } title={showUpdate ? 'Update' : 'Verify'} wrapperContainerStyle={{margin:"10px 20px"}}/>



    </div>
  )
}

export default UpdateUsername