import axios from 'axios';
import { differenceInDays, format, parseISO } from 'date-fns';
import { url, websocketurl } from '../config';


// create an instance of axios for authorized queries
export const authAxios = axios.create({
    baseURL : url,
    headers : {
        'Content-Type': 'application/json'
    }
})

authAxios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth-cg') ? JSON.parse(localStorage.getItem('auth-cg')) : null

        if(token) {
            config.headers['Authorization'] = 'Bearer ' + token?.token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// Auth related api's

export const login = async (username, password) => {
    return await axios.post(url + '/auth/login/', {username, password})
}

export const changePasswordFunction = async (id, currentPassword, newPassword) => {
    return await authAxios.post("/auth/change-password/", {id, currentPassword, newPassword})
}

export const getUserDetails = async (id) => {
    return await authAxios.post('/auth/get-user-details/', {id})
}

export const getProfiles = async (username) => {
    return await authAxios.post('/auth/get-profile/', {username})
}

export const updateTourAcceptanceStatus = async (_id, status, reasonText) => {
    return await authAxios.post('/auth/update-tour-acceptance-status/', {_id, status, reasonText})
}

export const updateUserDetails = async (form) => {
        const formData = new FormData();
        // Append other form fields
        formData.append('name', form.name);
        formData.append('about', form.about);
        formData.append('dob', form.dob);
        formData.append('gender', form.gender);
        formData.append('country', form.country);
        formData.append('countryCode', form.countryCode);
        formData.append('showCountryFlag', form.showCountryFlag);
        formData.append('state', form.state);
        formData.append('city', form.city);
        formData.append('zipCode', form.zipCode);
        formData.append('profession', form.profession);
        formData.append('isStudent', form.isStudent);
        formData.append('justifyInFewWords', form.justifyInFewWords);
        formData.append('hobbiesAndInterest', form.hobbiesAndInterest);
    
        // Append languages (if you need to send them as JSON, you can stringify)
        formData.append('languages', JSON.stringify(form.languages));
    
        // Append images only if they exist
        if (form.profileImage?.length > 10) {
            formData.append('profileImage', form?.profileImage);
        }
        if (form.image1 instanceof File || form?.image1?.length > 10) {
            formData.append('image1', form.image1);
        }
        if (form.image2 instanceof File || form?.image2?.length > 10) {
            formData.append('image2', form.image2);
        }
        if (form.image3 instanceof File || form?.image3?.length > 10) {
            formData.append('image3', form.image3);
        }
        if (form.image4 instanceof File || form?.image4?.length > 10) {
            formData.append('image4', form.image4);
        }

        try {
            const response = await authAxios.post('/auth/update-user-details/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error updating user details:', error);
            throw error;
        }
}

export const updateItineraryData = async (form) => {
    const formData = new FormData();
    formData.append('range', Object.keys(form).length)
    // Append itinerary data
    Object.keys(form).forEach((key) => {
        const itinerary = form[key];
    
        // Check if itinerary is an object
        if (!itinerary || typeof itinerary !== 'object') {
            console.error(`form[${key}] is undefined or not an object`);
            return;
        }
    
        // Append each itinerary title and description
        if (itinerary.title) {
            formData.append(`title${key}`, itinerary.title);
        }
        if (itinerary._id) {
            formData.append(`_id${key}`, itinerary._id);
        }
    
        if (itinerary.description) {
            formData.append(`description${key}`, itinerary.description);
        }
    
        // Append each image if it's available (check if it's a File object)
        if (itinerary.image instanceof File) {
            formData.append(`image${key}`, itinerary.image);
        } else {
            console.warn(`itinerary[${key}][image] is not a File object or is empty`);
        }
    });
    
    try {
        const response = await authAxios.post('/auth/update-itinerary-details/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating user details:', error);
        throw error;
    }
}

export const updateChargesDetails = async (form) => {
    return await authAxios.post('/auth/update-charges-details/', {...form})
}

export const getItineraryImages = async () => {
    return await authAxios.get('/auth/get-itinerary-details/')
}

export const getHourCharges = async () => {
    return await authAxios.get('/auth/get-hour-charges/')
}

export const storeIdentityVerification = async (form) => {
    
    const formData = new FormData()
    
    formData.append('user', form.user)
    formData.append('adhaarCard', form.adhaarCard)
    formData.append('panCard', form.panCard)
    formData.append('accountHolderName', form.accountHolderName)
    formData.append('bankName', form.bankName)
    formData.append('accountNumber', form.accountNumber)
    formData.append('ifscCode', form.ifscCode)
    formData.append('branchName', form.branchName)

    if (form.adhaarCardFront?.length > 10) {
        formData.append('adhaarCardFront', form.adhaarCardFront)
    }
    if (form.adhaarCardBack instanceof File || form?.adhaarCardBack?.length > 10) {
        formData.append('adhaarCardBack', form.adhaarCardBack)
    }
    if (form.panCardFront instanceof File || form?.panCardFront?.length > 10) {
        formData.append('panCardFront', form.panCardFront)
    }
    if (form.panCardBack instanceof File || form?.panCardBack?.length > 10) {
        formData.append('panCardBack', form.panCardBack)
    }
    if (form.passbookPhoto instanceof File || form?.passbookPhoto?.length > 10) {
        formData.append('passbookPhoto', form.passbookPhoto)
    }
    if (form.touristFacilitatorCertificate instanceof File || form?.touristFacilitatorCertificate?.length > 10) {
        formData.append('touristFacilitatorCertificate', form.touristFacilitatorCertificate)
    }
    if (form.policeClearanceCertificate instanceof File || form?.policeClearanceCertificate?.length > 10) {
        formData.append('policeClearanceCertificate', form.policeClearanceCertificate)
    }

    try {
        const response = await authAxios.post('/auth/identity-verification/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating user details:', error);
        throw error;
    }

}

export const updateContact = async (contact) => {
    return await authAxios.post('/auth/update-contact/', {contact})
}

export const addLinks = async (links) => {
    return await authAxios.post("/auth/add-link/", {links})
}

export const getSocialMediaLinks = async (id) => {
    return await authAxios.post('/auth/get-links/' , {id})
}

export const checkUsernameAvailability = async (username) => {
    return await authAxios.post('/auth/check-username-availability/', {username})
}

export const updateUsername = async (username) => {
    return await authAxios.post("/auth/update-username/", {username})
}

export const notifyMeFunction = async (stateName) =>{
    return await authAxios.post("/auth/notify-me/", {stateName})
}

export const fetchNotifications = async () => {
    return await authAxios.get("/auth/get-notifications/")
}

export const createNotification = async (toUser, text) => {
    return await authAxios.post('/auth/create-notification/', {toUser, text})
}

export const feedback = async (text, appExperience, guides, customerService, serviceRatings) => {
    return await authAxios.post('/auth/feedback/', {text, appExperience, guides, customerService, serviceRatings})
}

export const getTodaysEvent = async (date, month, year) => {
    return await authAxios.post('/auth/get-calendar-dates/', {date, month, year})
}
export const getEventsDates = async (month, year) => {
    return await authAxios.post('/auth/get-calendar-dates/', {month, year})
}

export const bookGuide = async (data) => {
    return await authAxios.post('/auth/book-guide/', {...data})
}
export const requestPersonalizedTour = async (data) => {
    return await authAxios.post('/auth/personalized-tour/', {...data})
}



export const verifyOtp = async (id, otp) => {
    return await authAxios.post('/auth/verify-tour/', {id, otp})
}

export const guides = async (city, id) => {
    return await authAxios.post('/auth/guides/', {city, id})
}

export const guideBookmarkStatus = async (guideid) => {
    return await authAxios.post('/auth/guide-bookmark-status/', {guideid})
}

export const savedGuides = async () => {
    return await authAxios.post('/auth/savedGuides/')
}

export const postRateAndReviews = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });
    return await authAxios.post('/auth/rate-and-reviews/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


export const getRateAndReviews = async (id) => {
    return await authAxios.get('/auth/rate-and-reviews/'+id+'/')
}

// block and unblock both is handled by this api only.

export const blockUser = async (id) => {
    return await authAxios.post('/auth/block-user/', {id})
}

export const getBlockedUser = async () => {
    return await authAxios.get('/auth/block-user/')
}

export const getFaq = async () => {
    return await authAxios.get('/auth/getFaq/')
}

export const createGuide = async (data) => {
    return await authAxios.post('/auth/store-guide-details/', data)
}

export const memberships = async () => {
    return await authAxios.get('/auth/memberships/')
}

export const createOrder = async (id) => {
    return await authAxios.post('/auth/create-order/', {id})
}

export const getChatsList = async () => {
    return await authAxios.post('/auth/chat-list/')
}

export const chatMetaInformation = async () => {
    return await authAxios.post('/auth/chat-meta-information/')
}

export const userActive = async (isActive) => {
    return  await authAxios.post('/auth/activity/', {isActive})
}

export const getUserActive = async (userId) => {
    return  await authAxios.get('/auth/activity/', {params:{userId}})
}

// Place related api's

export const categoryBasedPlaces = async (id) => {
    return await authAxios.post('/place/category-based-places/',{id})
}

export const placeBookmarkStatus = async (placeid) => {
    return await authAxios.post('/place/place-bookmark-status/', {placeid})
}

export const fetchEvents = async () => {
    return await authAxios.get('/place/trending-events/')
}

export const popularPlacesCategory = async () => {
    return await authAxios.get('/place/popular-places-category/')
}

export const fetchSavedPlacesAndGuides = async () => {
    return await authAxios.get('/place/saved-places-and-guides/')
}

export const fetchPlaceDetails = async (id) => {
    return await authAxios.post('/place/fetch-place-details/', {id})
}

export const storeIntoRecentSearch = async (id, searchText) => {
    return await authAxios.post('/place/store-recent-searches/', {id, searchText})
}

export const fetchRecentSearches = async () => {
    return await authAxios.get('/place/recent-searches/')
}
export const fetchTrendingStates = async () => {
    return await authAxios.get('/place/trending-states/')
}

export const search = async (text) => {
    return await authAxios.post('/place/search/', {search:text})
}

// External helper functions

export const checkIsNew = (createdAt) => {
    if (createdAt){
        const difference = differenceInDays(new Date(), parseISO(createdAt))
        return difference > 15 ? false : true
    }
    return false
}

// format year-month-date
export function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month because it's 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

// format : dd/mm/yy and time hh:mm
export const convertDateTime = (isoString) => {
    const date = new Date(isoString);
  
    // Format date as 'dd/MM/yy'
    const formattedDate = format(date, 'dd/MM/yy');
  
    // Format time as 'HH:mm'
    const formattedTime = format(date, 'HH:mm aa');
  
    return { formattedDate, formattedTime };
};

// chat room related 

// Fetch the chat room name from the backend
export const fetchRoomName = async (user2_id) => {
    const response = await axios.post('/api/create_or_get_chat_room/', { user2_id });
    return response.data.room_name;
};

export const getRoomName = (user1_id, user1_username, user2_id, user2_username) => {
    // this is based on the backend logic
    // in backend we are generating room name like user1_user2_chat
    // where user1.id is smaller than user2.id
    if(user1_id < user2_id) {
        return `${user1_username}_${user2_username}_chat`
    }
    return `${user2_username}_${user1_username}_chat`
}

// Move this function outside the component
export const GeneralSocket = (toUserId, fromUsername, generalSocketRef, message, eventType) => {
	// toUserId: to send the notification to that user
	generalSocketRef.current = new WebSocket(`${websocketurl}/ws/general-notifications/${toUserId}/`);
	
	// Ensure the WebSocket connection is open before sending
	generalSocketRef.current.onopen = () => {
		generalSocketRef.current.send(JSON.stringify({
			"message": message ? message : 'new message from ' + fromUsername,
			"eventType": eventType,
		}));

		// Close the WebSocket after sending the message
		generalSocketRef.current.close();
	};
};

export const ChatSocket = (room_name, fromUsername, message, chatSocketRef) => {
	// toUserId: to send the notification to that user
    chatSocketRef.current = new WebSocket(`${websocketurl}/ws/chat/${room_name}/`);
	
	// Ensure the WebSocket connection is open before sending
	chatSocketRef.current.onopen = () => {
        chatSocketRef.current.send(JSON.stringify({
            'message': message,
            'sender':fromUsername,
            'chatRoom':room_name
        }));

		// Close the WebSocket after sending the message
		chatSocketRef.current.close();
	};
};