import React, { useState } from 'react'
import { BackButton, ButtonComponent, OtpComponent } from '../../components'
import './forgotpasswordpage.styles.scss'
import { forgotPassword, success } from '../../assets/images'
import { PiArrowRight, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2';

const ForgotPasswordPage = () => {
  const [page, setPage] = useState(1)
  const [contact, setContact] = useState('')
  const [state, setState] = useState({
    otp1:'',
    otp2:'',
    otp3:'',
    otp4:''
})
  const navigate = useNavigate()
  const clear = () => {
    setState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
    })
  }


  
  return (
    <div className='px-10 my-20 forgot-password-page-container'>
 

      {/* Image, reset password and helper text, flag input , button */}
      {page === 1 &&
        <>
          {/* Back button */}
          <BackButton size={24} style={{marginLeft:-20}}/>

          {/* Image */}
          <div className='d-flex space-evenly my-20'>
            <img src={forgotPassword} alt='forgot password' />
          </div>

          {/* Reset Password : text */}
          <p className='reset-password'>Reset Password</p>

          {/* kindly enter registered : text */}
          <p className='help-text'>Kindly enter registered mobile number to reset your password.</p>

          {/* Flag input container */}
          <div className='w-100'>
              <PhoneInput
                  country={'in'}
                  value={contact}
                  onChange={phone => setContact(phone)}
              />
          </div>

          {/* Button */}
          <ButtonComponent title='Get Otp' onClick={()=>setPage(page + 1)}/>
        </>
      }

      {page === 2 &&
        <>
          {/* Back button */}
          <BackButton size={24} style={{marginLeft:-20}} onClick={()=>setPage(page - 1)}/>
            
          {/* Reset Password : text */}
          <p className='reset-password'>Enter OTP</p>

          {/* kindly enter registered : text */}
          <p className='help-text'>Please enter an otp sent to your registered mobile number *****4082.</p>

          {/* Otp Input */}
          <OtpComponent state={state} setState={setState}/>

          {/*  didn't receive otp ? RESEND OTP  : text*/}
          <p className='help-text'>Didn't receive otp ? <span>RESEND IT.</span></p>
          <div className='d-flex space-evenly'>
            <div class="dial-pad">
              <button class="dial-button">1</button>
              <button class="dial-button">2</button>
              <button class="dial-button">3</button>
              <button class="dial-button">4</button>
              <button class="dial-button">5</button>
              <button class="dial-button">6</button>
              <button class="dial-button">7</button>
              <button class="dial-button">8</button>
              <button class="dial-button">9</button>
              <button onClick={()=>clear()} class="dial-button">C</button>
              <button class="dial-button">0</button>
              <button onClick={()=>setPage(page + 1)} class="dial-button"><PiArrowRight /></button>
            </div>
          </div>
        </>
      }

      {page === 3 &&
        <>
          <div className='d-flex items-center'>
            {/* Back button */}
            <BackButton size={24} style={{marginLeft:-20}} onClick={()=>setPage(page - 1)}/>

            {/* Reset Password : text */}
            <p className='reset-password'>&nbsp;Reset Password</p>

            <p/>
          </div>

          {/* kindly enter registered : text */}
          <p className='help-text'>Your new password must be different from previously used password</p>
          <div className='px-10'>

            <div className='input-container'>
              <span className='label'>New Password</span>
              <input type='password' style={{borderLeft:0}} />
              <PiEyeSlash color='var(--primary)'/>
            </div>
            
            <div className='input-container'>
              <span className='label'>Cofirm Password</span>
              <input type='password' style={{borderLeft:0}} />
              <PiEyeSlash color='var(--primary)'/>
            </div>

            <ButtonComponent title='Change Password' onClick={()=>setPage(page + 1)}/>
          </div>

            
        </>
      }

      {page === 4 &&

        <div className='my-20'>
          <div className='d-flex space-evenly'>
            <img src={success} alt='success' />
          </div>

          <p className='reset-password'>Success</p>

        {/* kindly enter registered : text */}
        <p className='help-text'>Your password has been<br/>updated successfully.</p>

        <ButtonComponent title='Okay' wrapperContainerStyle={{margin:"10px 20px"}} onClick={()=>navigate('/Login')}/>

            

        </div>
      }

    </div>
  )
}

export default ForgotPasswordPage