import React from 'react'
import {BackButton, ButtonComponent} from '../../components'
import { support } from '../../assets/images'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

const SupportPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <BackButton size={24} text='Support' />
      <div className='p-20'>
          {/* header : back, title */}

          <div className='d-flex space-evenly'>
              <img src={support} alt='support city2guide' />
          </div>
              
          <div className='p-10 bg-lightgray border-rounded d-flex items-center'>
              <PiMagnifyingGlass />
              <input className='text-lightgray px-20 bg-transparent border-none' placeholder='How we can help you ?'></input>
          </div>

          <p className='my-20'>Search from our vast and informational documentations </p>

          <h3 className='fw-500 my-10'>Quick Links</h3>
          <p className='fw-400 fs-14 my-5 text-lightgray'>Contact us</p>
          <p className='fw-400 fs-14 my-5 text-lightgray'>Emergency Helpline</p>
          <p className='fw-400 fs-14 my-5 text-lightgray'>Customer Care</p>
          <p className='fw-400 fs-14 my-5 text-lightgray'>Report</p>

          <br/>
          
          <p>If the issue still persists , </p>
          {/* <ButtonComponent title='Call Us' wrapperContainerStyle={{margin:'20px 0px 10px 0px'}}/> */}
          <ButtonComponent wrapperContainerStyle={{margin:"30px 0px"}} onClick={()=>navigate('/ContactUs')} title='Write to Us'/>
      </div>
    </>
  )
}

export default SupportPage