import React, { useEffect, useState } from 'react'
import { Facebook, Instagram, Linkedin, Pinterest, Twitter, Youtube } from '../../assets/icons'
import './addlinkspage.styles.scss'
import { BackButton, ButtonComponent } from '../../components'
import { addLinks, getSocialMediaLinks } from '../../utils/service'
import { notify } from '../../components/Toast'

const LINKS = [
    {name:'Facebook', image:Facebook},
    {name:'Instagram', image:Instagram},
    {name:'LinkedIn', image:Linkedin},
    {name:'Youtube', image:Youtube},
    {name:'Pinterest', image:Pinterest},
    {name:'Twitter', image:Twitter},
]


const IconTextRenderer = ({name, image, ...props}) => {
    return(
        <div className='icon-text-renderer d-flex items-center'>
            <img src={image} alt={name} />
            <input placeholder={`Add ${name} Profile`} {...props} />
        </div>
    )
}

const AddLinksPage = () => {
    const [loading, setLoading] = useState(true)
    const [linkInputs, setLinkInputs] = useState({
        'Facebook':'',
        'Instagram':'',
        'LinkedIn':'',
        'Youtube':'',
        'Pinterest':'',
        'Twitter':''
    })

    const updateHandler = () => {
        addLinks(linkInputs).then(()=>{
            notify('Links Updated.')
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    useEffect(()=>{
        getSocialMediaLinks().then(res=>{
            setLinkInputs({
                'Facebook':res.data?.['Facebook'] || '',
                'Instagram':res.data?.['Instagram'] || '',
                'LinkedIn':res.data?.['LinkedIn'] || '',
                'Youtube':res.data?.['Youtube'] || '',
                'Pinterest':res.data?.['Pinterest'] || '',
                'Twitter':res.data?.['Twitter'] || ''
            })
        }).catch(err =>{
            notify('Failed to get the links')
        }).finally(()=>{
            setLoading(false)
        })
    }, [])
    
  return (
    <>
        <BackButton size={24} text='Add Links' />
        {loading &&
            <div className='d-flex h-100 content-center'>
                <div className='loader'>
                </div>
            </div>
        }
        {!loading &&
            <div className='px-20 my-20'>
                <h1>Links</h1>
                {LINKS.map(item => (
                    <IconTextRenderer
                        key={item.id}
                        value={linkInputs[item.name]}
                        onChange={(e)=>setLinkInputs({...linkInputs, [item.name]:e.target.value})} 
                        name={item.name}
                        image={item.image}
                    />
                ))}
                <ButtonComponent onClick={()=>updateHandler()} title='Save' wrapperContainerStyle={{position:"absolute", bottom:20, width:'84%'}}/>
            </div>
        }
    </>
  )
}

export default AddLinksPage