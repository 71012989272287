import React from 'react'
import { BackButton } from '../../components'
import { PiClock } from 'react-icons/pi'
const TermsAndConditionsPage = () => {
  return (
    <div>
        {/* header : back, title */}
        <BackButton size={24} text='Terms & Conditions' />

        <div className='px-20 my-20'>
          {/* last updated text with icon */}
          <div className='d-flex items-center my-20'>
            <PiClock color='var(--primary)' size={24}/>
            <p className='text-primary px-10'>Last updated on 23rd January , 2024</p>
          </div>

          <div>
            <p className='text-lightgray'>Help protect your website and its users with clear and fair website terms and conditions. These terms and conditions for a website set out key issues such as acceptable use.</p>
            <p className='fw-600 my-10'>1. Introduction</p>
            <p className='text-lightgray my-10'>
              These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Website.com.<br/>
            </p>
            <p className='text-lightgray my-10'>
              These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.<br/>
            </p>
            <p className='text-lightgray my-10'>
              Minors or people below 18 years old are not allowed to use this Website.
            </p>
            <p className='fw-600 my-10'>2. Intellectual Property Rights</p>
              <p className='text-lightgray my-10'>Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
              <p className='text-lightgray my-10'>You are granted limited license only for purposes of viewing the material contained on this Website.</p>
          </div>
        </div>
        
    </div>
  )
}

export default TermsAndConditionsPage