import React, { useContext, useRef, useState } from 'react'
import './personalizedtour.styles.scss'
import { PiMagicWandFill } from 'react-icons/pi'
import {BasicBookingForm, ButtonComponent} from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'
import { ChatSocket, getRoomName, requestPersonalizedTour } from '../../utils/service'
import { notify } from '../../components/Toast'


const LabelInput = ({label, state, setState, textField, ...props}) => {
  return(
    <div className='labelAndInput-container'>
      <h2>{label}</h2>
      {!textField ? 
        <input {...props}/>
        :
        <textarea {...props}></textarea>
      }
    </div>
  )
}

const PersonalizedTour = () => {
  const navigate = useNavigate()
	const chatSocketRef = useRef()
  const location = useLocation()
  const { userInfo } = useContext(StateContext)
  const { id, image, fullName, username, city} = location.state || {};
  const [firstWarning, setShowFirstWarning] = useState(false)
  const [data, setData] = useState({
    'specialOccasion':'',
    'bucketList':'',
    'manyOrOnePlace':'',
    'exploreHiddenGem':'',
    'withWhom':'',
    'anyCustomization':''
  })


  const clickBasicBookingFormUsingId = () => {
    for(let key of Object.keys(data)){
      if(data[key].length === 0){
        return notify('Please fill in the empty fields.')
      }
    }

    if(!firstWarning) {
      alert('are you sure you want to proceed? we will send personalize details along with the date time adult and children details.')
      setShowFirstWarning(true)
      return
    }

    const element = document.getElementById('basic-booking-form')

    if(element){
      element.click()
    }else{
      alert('element not found')
    }
  }

  const clickHandler = (basicFormId) => {
    requestPersonalizedTour({...data, username, basicFormId}).then(res => {
      notify('request send to the guide')
      const roomName = getRoomName(userInfo?.id, userInfo?.username, id, username)
      const message = `are you visiting ${city} for special occasion?\n<b>${data.specialOccasion}</b>\n
        what is top of your bucket list?\n<b>${data.bucketList}</b>\n
        would you prefer to see lot of places or get to know one really well?\n<b>${data.manyOrOnePlace}</b>\n
        would you like to explore hidden gems of my city?\n<b>${data.exploreHiddenGem}</b>\n
        with whom will you be joining?\n<b>${data.withWhom}</b>\n
        is there anything you"d like me to customize for your experience?\n<b>${data.anyCustomization}</b>\n
      `
      ChatSocket(roomName, userInfo?.username, message, chatSocketRef)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  return (
    <div>
      {/* Header card container */}
      <BasicBookingForm
        guideUsername={username} 
        image={image} 
        fullName={fullName} 
        user1_id={userInfo?.id}  
        user1_username={userInfo?.username}
        guideId={id}
        requestPersonalizedTourFunction={clickHandler}
      /> 

      {/* personalize your experience text */}
      <div className='d-flex my-10 items-center px-10'>
        <PiMagicWandFill size={20}/>
        <p className='px-5 personalize-text'>Let's Personalize your experience !</p>
      </div>

      {/* label & input box */}
      <LabelInput value={data.specialOccasion} onChange={(e)=>setData({...data, 'specialOccasion':e.target.value})} label={`are you visiting ${city} for special occasion ?`}/>
      <LabelInput value={data.bucketList} onChange={(e)=>setData({...data, 'bucketList':e.target.value})} label='what is top of your bucket list ?'/>
      <LabelInput value={data.manyOrOnePlace} onChange={(e)=>setData({...data, 'manyOrOnePlace':e.target.value})} label='would you prefer to see lot of places or get to know one really well ?'/>
      <LabelInput value={data.exploreHiddenGem} onChange={(e)=>setData({...data, 'exploreHiddenGem':e.target.value})} label='would you like to explore hidden gems of my city ?'/>
      <LabelInput value={data.withWhom} onChange={(e)=>setData({...data, 'withWhom':e.target.value})} label='with whom will you be joining ?'/>
      <LabelInput value={data.anyCustomization} onChange={(e)=>setData({...data, 'anyCustomization':e.target.value})} textField={true} label='is there anything you"d like me to customize for your experience ?' placeholder='e.g. Yes! we"d like to add some local dishes to this experience.' style={{height:70}}/>

      {/* Buttons */}
      <ButtonComponent onClick={()=>clickBasicBookingFormUsingId()} title='Request for personaized tour' wrapperContainerStyle={{margin:10, padding:10}} />
      <ButtonComponent onClick={()=>navigate('/Chat', {state:{userId:id, image:image, fullName, username}})} iconColor='var(--primary)' title='Or just ask me a question' wrapperContainerStyle={{padding:10, margin:"10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} buttonTextColor='var(--primary)'/>


      
    </div>
  )
}

export default PersonalizedTour