import React from 'react'
import './profilemessage.styles.scss'

const ProfileMessage = ({message, date, time, self=true}) => {
  
  return (
    <div className={`profile-message-container ${!self ? 'flex-end' : ''}`}>
        {/* image */}
        {/* {self &&
            <img src={image} alt='' />
        } */}
        {/* message */}
        <div className={`message-container-pm mx-7 ${!self ? 'not-self'  : ''}`}>
            <p dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }} />
            {time &&
            <>
                <p className='timestamp'>
                  {time}
                </p>
            </>
            }
        </div>
        {/* {!self &&
            <img src={image} alt='' />
        } */}
    </div>
  )
}

export default ProfileMessage