import React, { useEffect, useState } from 'react'
import { AccordionDefault, BackButton } from '../../components'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { faqImage } from '../../assets/images'
import { getFaq } from '../../utils/service'
import { notify } from '../../components/Toast'


const FAQPage = () => {

  const [data, setData] = useState([])

  useEffect(()=>{
    getFaq().then(res =>{
      setData(res.data)
    }).catch(err => 
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    )
  },[])
  return (
    <div className='px-10'>
        <BackButton size={24} style={{marginLeft:-10}} text='FAQ'/>
        
        <h1 className='fw-600 text-center my-10 text-primary'>Welcome to FAQ</h1>

        <div className='p-10 bg-lightgray border-rounded d-flex space-between items-center'>
            <input className='text-lightgray px-10 bg-transparent border-none w-100' placeholder='Search your questions here '></input>
            <PiMagnifyingGlass />
        </div>
        <div className='d-flex space-evenly'>
          <img src={faqImage} alt='FAQ'/>
        </div>



        <AccordionDefault items={data} />


          
        
    </div>
  )
}

export default FAQPage
