import React from 'react'
import { PiCaretLeftLight  } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

const BackButton = ({size, text='Back', onClick, ...props}) => {
  
  const navigate = useNavigate()

  return (
    <div onClick={onClick ? onClick : ()=>navigate(-1)} className='cursor-pointer d-flex items-center px-10 d-flex my-20' {...props}>
      <PiCaretLeftLight className='custom-svg' size={size || 24}  />
      {text}
    </div>
  )
}

export default BackButton