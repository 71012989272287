import React, { useEffect, useRef, useState } from 'react'
import './detailspage.styles.scss'
import { PiAirplane, PiBookmarkSimple, PiBookmarkSimpleFill, PiTrain } from 'react-icons/pi'
import { BackButton, ButtonComponent, Loader } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchPlaceDetails, placeBookmarkStatus } from '../../utils/service'
import { notify } from '../../components/Toast'

const DetailsPage = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { id } = location.state || {};
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [isBookmarked, setIsBookmarked] = useState(false)
  const apiFired = useRef(false)

  useEffect(()=>{
    if(id && !apiFired.current){
      apiFired.current = true
      fetchPlaceDetails(id).then(res => {
        setData(res.data)
        setLoading(false)
        setIsBookmarked(res.data.isSaved)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
    }
  },[id])

  const updatePlaceBookmarkStatus = () => {
    placeBookmarkStatus(data.id); 
    setIsBookmarked(!isBookmarked)
  }

  return (
    <>
      {loading ? 
        <Loader />
        :
        <div className='details-page-container'>
          {/* back and save */}
          <div className='icons-container d-flex space-between'>
              <BackButton size={29} style={{margin:0, marginLeft:-10}} text=''/>

              {isBookmarked ? 
                <PiBookmarkSimpleFill onClick={()=>updatePlaceBookmarkStatus()} size={30} style={{color:"rgb(243, 243, 143)"}} />
                :
                <PiBookmarkSimple onClick={()=>updatePlaceBookmarkStatus()} size={30} />
              }
          </div>
          <div className='image-card-container-dp'>
            <img src={data.image} alt='india gate' />
          </div>
            
          {/* badge, meta information container */}
          <div className='d-flex content-center'>
            <div className='information-container'>
              <p className='badge'>{data.categoryName}</p>
              <h1>{data.name}, <span>{data.stateName}</span></h1>
              <div className='column'>
                <div className='d-flex items-center'>
                  <PiAirplane size={24} style={{rotate:'45deg'}} />
                  <p className='info-text'>{data.distanceByPlane} km away from {data.stateName} airport.</p>
                </div>
                <div className='d-flex items-center'>
                  <PiTrain size={24} />
                  <p className='info-text'>{data.distanceByTrain} km away from {data.stateName} station.</p>
                </div>
                {/* <div className='d-flex items-center'>
                  <PiTaxi size={24} />
                  <p className='info-text'>{data.distanceByTaxi} km away from {data.stateName} taxi stand.</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className='text-container'>
            {/* Description Text */}
            <h1 className='title-text'>Description</h1>
            <p className='information-text'>
              {data.description}
            </p>
          </div>
          {/* Button */}
          <ButtonComponent onClick={()=>navigate('/Guides-List', { state: { stateName:data.stateName } })} wrapperContainerStyle={{margin:"20px", alignSelf:"center"}} title='Book Guide' />
        </div>
      }
    </>
  )
}

export default DetailsPage