import React, { useState } from 'react'
import {PiPhoneCallFill } from 'react-icons/pi'
import { BackButton, ButtonComponent } from '../../components'

const EmergencyPage = () => {
    const [showMessage, setShowMessage] = useState(false)
  return (
    <div>
        <BackButton size={24}/>
        
        <h1 className='text-center fw-600 my-20'>Emergency Help <br/> Needed ?</h1>

        <div className='d-flex content-center'>
            <div onClick={()=>setShowMessage(true)} className='bg-lightgray border-rounded d-flex items-center p-20  my-20 w-80'>
                <PiPhoneCallFill size={22}/>
                <p className='px-10'>Customer Care</p>
            </div>
        </div>
        <div className='position-absolute w-100' style={{bottom:'20px'}}>
            {showMessage &&
                <p className='text-center my-10'>Email & SMS Sent Successfully</p>
            }
            <ButtonComponent title='Done' wrapperContainerStyle={{margin:"0px 20px"}}/>
        </div> 
    </div>
  )
}

export default EmergencyPage