import React from 'react'
import './icondropdown.styles.scss'

const IconDropDown = ({Icon, children}) => {
  return (
    <div className='d-flex items-center icon-dropdown-container'>
      {Icon &&
        <Icon size={30} />
      }
      {children}
    </div>
  )
}

export default IconDropDown