import { createContext, useState } from "react";
import { getChatsList } from "../utils/service";
import { notify } from "../components/Toast";
export const StateContext = createContext()

const StateProvider = ({children}) => {
    const [isTourist, setIsTourist] = useState(false)
    const [chatHomePageData, setChatHomePageData] = useState([])
    const [chatsList, setChatsList] = useState([])
    const [userInfo, setUserInfo] = useState(false)
    const [showNewMessageBadge, setShowNewMessageBadge] = useState(false)
    const [showNotificationBadge, setShowNotificationBadge] = useState(false)
    const [showNewBookingBadge, setShowNewBookingBadge] = useState(false)
    
    const [userForm, setUserForm] = useState({
        'fullName':'',
        'dob':'',
        'gender':'',
        'contact':'',
        'email':'',
        'country':'India',
        'state':'',
        'city':'',
        'cityOfService':'',
        'profession':'',
        'isStudent':false,
        'justifyInFewWords':'',
        'languages':
            {
                0 : {'language':'', 'fluency':''} 
            }
        ,
        'hobbiesAndInterest':'',
        'skillOrTalent':"",
        'isGuide':'',
        'username':'',
        'password':'',
        'whatsappNumber':'',
    })
    const [identityVerificationForm, setIdentityVerificationForm] = useState({
        'adhaarCard':'',
        'adhaarCardFront':'',
        'adhaarCardBack':'',
        'panCard':'',
        'panCardFront':'',
        'panCardBack':'',
        'accountHolderName':'',
        'bankName':'',
        'accountNumber':'',
        'ifscCode':'',
        'branchName':'',
        'passbookPhoto':'',
        'touristFacilitatorCertificate':'',
        'policeClearanceCertificate':''
    })
    const chatListHandler = () =>{
        getChatsList().then(res =>{
            setChatHomePageData(res.data)
            setChatsList(res.data)
          }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }
    return(
        <StateContext.Provider
        value={{
            userInfo, setUserInfo,
            userForm, setUserForm,
            isTourist, setIsTourist,
            identityVerificationForm, setIdentityVerificationForm,
            chatListHandler,
            chatHomePageData, setChatHomePageData,
            chatsList, setChatsList,
            showNewMessageBadge, setShowNewMessageBadge,
            showNotificationBadge, setShowNotificationBadge,
            showNewBookingBadge, setShowNewBookingBadge
        }}
        >
            {children}
        </StateContext.Provider>
    )
}

export default StateProvider


