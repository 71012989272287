import React, { useContext, useEffect, useState } from 'react'
import './homepage.styles.scss'
import { BsCalendar2Check, BsChatDots, BsNintendoSwitch } from 'react-icons/bs'
import { ChipComponent, SearchFilterInput, SideDrawer } from '../../components'
import { ImageCard } from '../../components'
import { useNavigate } from 'react-router-dom'
import { categoryBasedPlaces, fetchEvents, placeBookmarkStatus, popularPlacesCategory } from '../../utils/service'
import { StateContext } from '../../context/stateContext'

const EVENT_CARD_STYLE = {
  maxHeight:200, minHeight:200, maxWidth:300, minWidth:300
}

const HomePage = () => {
  
  const navigate = useNavigate()
  const {userInfo} = useContext(StateContext)
  const [selectedPopularPlace, setSelectedPopularPlace] = useState('')
  const [showSideDrawer, setShowSideDrawer] = useState(false)
  const [shake, setShake] = useState(true)
  const [chips, setChips] = useState([])
  const [places, setPlaces] = useState([])
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchCategoryBasedPlaces = (id) => {
    categoryBasedPlaces(id).then(res => {
      setPlaces(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
    })
  } 

  useEffect(()=>{
    if(selectedPopularPlace?.id){
      fetchCategoryBasedPlaces(selectedPopularPlace?.id)
    }
  },[selectedPopularPlace])

  useEffect(()=>{
    // fetch events
    fetchEvents().then(res => {
      setEvents(res.data)
    }).catch(err => console.log(err))
    // fetch popular places category
    popularPlacesCategory().then(res => {
      setChips(res.data)
      setSelectedPopularPlace(res.data[0])
      
      // once response is received we can fetch category based places
      fetchCategoryBasedPlaces(res.data[0].id)
    }).catch(err => console.log(err))
    
    // shake animation related code
    const timer = setTimeout(()=>{
      setShake(false)
    }, 10000)

    return() => {
      clearTimeout(timer)
    }
  },[])

  return (
    <div className='homepage-container'>
        {/* SideDrawer Import */}
        <SideDrawer isOpen={showSideDrawer} setIsOpen={setShowSideDrawer}/>        

        {/* Greetings , Icons, Drawer */}
        <div className='header-container'>
          <div>
            <h1>Hi, {userInfo.username} <span className={`${shake ? 'rotate' : ''}`} style={{fontSize:30}}>👋</span></h1>
            <p>Explore the Uncommon</p>
          </div>
          <div className='home-quick-actions' style={{marginBottom:12}}>
              <div className='container'  onClick={()=>navigate('/Calendar')}>
                <BsCalendar2Check size={26} color='var(--black)' />
                  <div className='badge'>
                    <span>*</span>
                  </div>
              </div>
              <div className='container'  onClick={()=>navigate('/ChatHome')}>
                  <BsChatDots size={27} color='var(--black)' />
                  <div className='badge'>
                    <span>*</span>
                  </div>
              </div>
              <div className='container' onClick={()=>setShowSideDrawer((prevState) => !prevState)}>
                <BsNintendoSwitch size={26} color='var(--black)'/>
                  <div className='badge'>
                    <span>*</span>
                  </div>
              </div>
          </div>
        </div>
        
        {/* Search & Filter */}
        <SearchFilterInput onFocus={()=>navigate('/Search')}/>

        {/* Popular Places and View all : Text */}
        <div className='popular-places-text-container'>
          <h1>Popular Places</h1>
          <p>View All</p>
        </div>

        {/* Places Category Tabs */}
        <div className='chip-container'>
          {chips.map((item, index) => (
              <ChipComponent 
              style={{marginRight: index === chips.length - 1 ? '0px' : '10px'}} 
              selected={item.name === selectedPopularPlace?.name} 
              key={item.name + index} text={item.name} 
              onClick={()=>setSelectedPopularPlace(item)}/>
          ))}
        </div>

        {/* Places Cards */}
        <div className='places-card-container'>
            {loading ?
              <p>Loading indicator..</p>  
              :
              <>
                {places.map(item => {
                  return(
                    <ImageCard 
                      key={item.id}
                      _id={item.id}
                      redirectText='/Details'
                      metaRedirectText={{"id":item.id}}
                      style={{minWidth:217, backgroundImage:`url(${item.image})` }} 
                      placeName={item.name} 
                      bookmarked={item.isSaved}
                      bookmarkFunction={placeBookmarkStatus}
                      visitorsCount={item.visitorsCount}
                      />
                  )
                })}
              </>
            }
        </div>

        {/* Events & View all : Text */}
        <div className='events-text-container'>
          <h1>Events</h1>
          <p>View All</p>
        </div>

        {/* Events Cards */}
        <div className='places-card-container'>
          {events.map(item => (
            <ImageCard 
              key={item.id} 
              _id={item.id}
              redirectText='/Details'
              metaRedirectText={{"id":item.id}}
              style={{...EVENT_CARD_STYLE, backgroundImage:`url(${item.image})`}} 
              placeName={item.name} visitorsCount={item.visitorsCount}
              bookmarked={item.isSaved}
              bookmarkFunction={placeBookmarkStatus}
            />
          ))}
        </div>
    </div>
  )
}

export default HomePage