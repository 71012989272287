import React, { useEffect, useState } from 'react'
import './notificationpage.styles.scss'
import { BackButton } from '../../components'
import { PiArrowsDownUp, PiBookmarkSimpleFill, PiCaretRightBold, PiLock, PiPhoneFill, PiStar, PiWarningFill,  PiXCircleFill } from 'react-icons/pi'
import { blurUser, noNotifications } from '../../assets/images'
import { fetchNotifications, updateTourAcceptanceStatus } from '../../utils/service'
import { notify } from '../../components/Toast'
import { formatDate, formatDistance, parseISO, subDays } from 'date-fns'
import { WiStars } from "react-icons/wi";
import { BsHandIndexThumb, BsMap } from "react-icons/bs";
import { IoShieldCheckmark } from 'react-icons/io5'
import { MdCelebration } from 'react-icons/md'

const NotificationsRenderer = ({day, notifications=[], onClick}) => {
  const date = parseISO(day); 
  
  return(
    <>
      <h4 className='fw-500 my-10'>{formatDate(date, 'dd MMMM (eeee) , yyyy ')}</h4>
        {notifications.map(item => {
          const createdAt = parseISO(item.createdAt); 
          return(
            <>
              <div key={item.id} className='my-20 d-flex items-center bg-lightgray border-rounded py-10 space-between'>
                <div className='d-flex items-center'>
                  {
                    item.text?.toLowerCase()?.includes('reported') ?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <PiWarningFill size={28} color='crimson'/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('saved') ?
                      <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiBookmarkSimpleFill size={28} color='orange'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('star') ?
                      <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiStar size={28} color='orange'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('contact') ?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <PiPhoneFill size={28} color='green'/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('nudge') ?
                    <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                      <WiStars style={{position:"absolute", top:-2, left:14}} size={20}/>
                      <BsHandIndexThumb size={22}/>
                    </div>
                  :
                    item.text?.toLowerCase()?.includes('requested') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <BsMap style={{position:"absolute", top:-2, left:14}} size={20}/>
                        <BsHandIndexThumb size={22}/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('otp generated') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <PiLock size={30} color='rgba(0, 0, 0, 0.8)'/>
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('been verified') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        <MdCelebration  color='purple' size={30} />
                      </div>
                  :
                    item.text?.toLowerCase()?.includes('accepted') || item.text?.toLowerCase()?.includes('rejected') ?
                      <div style={{position:"relative", display:"flex", justifyContent:"center", alignItems:"center", height:50, width:50, borderRadius:'50%'}}>
                        {item.text?.toLowerCase()?.includes('accepted') ?
                          <IoShieldCheckmark color='green' style={{position:"absolute", left:14}} size={28}/>
                          :
                          <PiXCircleFill color='red' style={{position:"absolute", left:15}} size={30}/>

                        }
                      </div>
                  :
                    <img src={blurUser} style={{backdropFilter:"blur(10)", height:50, width:50, borderRadius:'50%'}} alt='' />
                  }
                  <div className='column px-10'>
                    <p className='fw-600'>{item.text}</p>
                    <p className='color-gray' style={{fontSize:12}}>{formatDistance(subDays(createdAt, 0), new Date(), { addSuffix: true })}</p>
                  </div>
                </div>
                <PiCaretRightBold size={22}/>
              </div>
              {item.text?.toLowerCase()?.includes('requested') && (item.status === null || item.status === 'undefined') &&
                <div style={{marginTop:-20}} className='buttons-container bg-lightgray p-5 d-flex flex-end items-center'>
                    <button className='cursor-pointer' onClick={()=>onClick(item._id, 'cancel')}>Cancel</button>
                    <button className='cursor-pointer' onClick={()=>onClick(item._id, 'accept')}>Accept</button>
                </div>
              }
            </>
          )
        })}
    </>
  )
}

const NotificationPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const getNotifications = () => {
    fetchNotifications().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    }).finally(()=>{
      setLoading(false)
    })
  }
  
  useEffect(()=>{
    getNotifications()
  },[])

  const tourStatusHandler = (_id, status) => {
    updateTourAcceptanceStatus(_id, status).then(res => {
      notify('Status updated')
      getNotifications()
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  return (
    <div className='px-20 my-20'>
      {/* Header : back icon, notifications text, sort by text, updown arrow */}
      <div className='d-flex space-between items-center'>
        <BackButton style={{marginLeft:"-20px", marginRight:10}} size={22} />
        <h2 className='fw-500 px-10'>Notifications</h2>
        <div className='d-flex items-center'>
          <p className='color-gray px-5' style={{fontSize:14}}>Sort By</p>
          <PiArrowsDownUp size={20}/>
        </div>
      </div>
      {loading ?
      <div className='d-flex content-center items-center'>
          <p className='text-center'>Loading...</p>
      </div>
      :
      <>
        {Object.keys(data).length === 0 && !loading ?
          <div className='d-flex h-100 column content-center items-center'>
            <div className='d-flex content-center'>
              <img className='my-20' src={noNotifications} alt='No Notification'/>
            </div>
            <p>No Notifications yet.</p>
          </div>
        :
        <>
          {Object.keys(data).map(key => (
            <NotificationsRenderer key={key} day={key} notifications={data[key]} onClick={tourStatusHandler}/>
          ))}
        </>
        }
      </>
      }




    </div>
  )
}

export default NotificationPage