import React, { useRef } from 'react'
import './otpcomponent.styles.scss'
const OtpComponent = ({state, setState}) => {

    const inputRef1 = useRef()
    const inputRef2 = useRef()
    const inputRef3 = useRef()
    const inputRef4 = useRef()
    
    const changeHandler = (key, text, ref) => {
        if(state[key].length <= 0 || text?.length <= 1){
            setState({...state, [key]:text})
            if(text.length !== 0){
                ref?.current?.focus()
            }
        }
        else{
            ref?.current?.focus()
        }
    }
    return (
        <div className='otp-boxes-container'>
            <input ref={inputRef1} value={state.otp1} onChange={(e)=>changeHandler('otp1', e.target.value, inputRef2)} type='number' className='otp-box'/>
            <input ref={inputRef2} value={state.otp2} onChange={(e)=>changeHandler('otp2', e.target.value, inputRef3)} type='number' className='otp-box'/>
            <input ref={inputRef3} value={state.otp3} onChange={(e)=>changeHandler('otp3', e.target.value, inputRef4)} type='number' className='otp-box'/>
            <input ref={inputRef4} value={state.otp4} onChange={(e)=>changeHandler('otp4', e.target.value)} type='number' className='otp-box'/>
        </div>
    )
}

export default OtpComponent