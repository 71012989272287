import React from 'react'
import { BackButton } from '../../components'
import { PiBank, PiPlusBold } from 'react-icons/pi'
import './mywalletpage.styles.scss'

const MyWallet = () => {
  return (
    <>
        <BackButton />
        <div className='px-20'>
            {/* Icons */}
            <div className='d-flex space-between items-center'>
                <h3 className='px-20 text-title'>My Wallet</h3>
                <div className='d-flex position-relative'>
                  <PiBank color='gray' size={30}/>
                  <PiPlusBold color='gray' style={{position:"absolute", background:'white', right:-4, bottom:-4}}/>
                </div>
            </div>

            {/* Container */}
            <div className='mywallet-balance-container'>
              <p>My Balance</p>
              <span>3000.00</span>
            </div>
        </div>
    </>
  )
}

export default MyWallet