import React, { useContext, useState } from 'react'
import './sidedrawer.styles.scss'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { PiBellFill, PiBookmarkThin, PiCirclesThreePlusThin, PiCrownThin, PiHeadsetThin, PiLockSimpleThin, PiNote, PiNotePencil, PiPencil, PiPhoneCall, PiSealCheckThin, PiWalletThin } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { CiSettings } from 'react-icons/ci'
import CircularProgress from '../CircularProgress'
import { StateContext } from '../../context/stateContext'
import { MdOutlineSos } from 'react-icons/md'
import { userActive } from '../../utils/service'

const DRAWER_ITEMS = [
	{Icon:PiCrownThin, name:'Membership', url:'/Membership'},
	{Icon:PiSealCheckThin, name:'Profile Verification', url:'/Profile-Verification/identity-verification'},
	{Icon:PiBookmarkThin, name:'Favourite Places & Profile', url:'/Favourites'},
	{Icon:CiSettings, name:'Settings', url:'/Settings'},
	// {Icon:PiTranslateThin, name:'Language', url:''},
	{Icon:PiCirclesThreePlusThin , name:'More', url:'', 
        options:[
                {Icon:PiNote, name:'terms and conditions', url:'/Terms-Conditions'},
                {Icon:PiNotePencil, name:'feedback', url:'/Feedback'},
                {Icon:PiPhoneCall, name:'contact', url:'/ContactUs'}]},
	{Icon:PiHeadsetThin , name:'Support', url:'/Support'},
	{Icon:PiHeadsetThin , name:'SOS', url:'/Emergency'},
];

const DRAWER_ITEMS_GUIDE = [
	{Icon:PiWalletThin, name:'My Wallet', url:'/My-Wallet'},
	{Icon:PiCrownThin, name:'Membership', url:'/Membership'},
	{Icon:PiSealCheckThin, name:'Profile Identity Verification', url:'/Profile-Verification/identity-verification'},
	{Icon:CiSettings, name:'Settings', url:'/Settings'},
	// {Icon:PiImageThin, name:'Itinerary', url:'/Itinerary'},
	{Icon:PiCirclesThreePlusThin , name:'More', url:'', 
        options:[
                {Icon:PiNote, name:'terms and conditions', url:'/Terms-Conditions'},
                {Icon:PiNotePencil, name:'feedback', url:'/Feedback'},
                {Icon:PiPhoneCall, name:'contact', url:'/ContactUs'}]},
	{Icon:PiHeadsetThin , name:'Support', url:'/Support'},
	{Icon:PiHeadsetThin , name:'SOS', url:'/Emergency'},
];

const ListItem = ({Icon, name, url, state, showBadge, setState, options=[]}) => {
	const navigate = useNavigate()
	return(
		<>	
			{name === 'SOS' &&
				(
					<div className='list-item emergency-number' onClick={()=>navigate('/Emergency')}>
						{/* <h4 className='item-text'>SOS</h4> */}
						<MdOutlineSos color='gray' size={60}/>
					</div>	
				)
			}
			{name !== 'SOS' &&
				<>
					<div key={`${name}-${url}`} className={`list-item ${state ? 'mb-0' : ''}`} onClick={options.length > 0 ? ()=>setState(!state) : url?.length === 0 ? ()=>true : ()=>navigate(url)}>
						<Icon size={24}/>
						<h4 className='px-5 item-text'>{name}</h4>
						{showBadge &&
							<div style={{height:15, width:15, marginLeft:5, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", backgroundColor:'var(--primary)'}}>
								<p style={{fontSize:10, marginTop:2, color:"white"}}>5</p>
							</div>
						}
					</div>
					{state &&
						<div className={`options-container`}>
							{options.map(item => (
								<div className='d-flex items-center' onClick={()=>navigate(item.url)} key={item.id}>
									<item.Icon />
									<p className='item-text'>{item.name}</p>
								</div>
							))}
						</div>
					}
				</>
			}
		</>
	)
}


const SideDrawer = ({isOpen, setIsOpen}) => {
	const navigate = useNavigate()
	const { userInfo, setUserInfo} = useContext(StateContext)
	const [showOptions, setShowOptions] = useState(false)

	const logoutHandler = () => {
		userActive(false).then(() => {
			setUserInfo(false)
			localStorage.removeItem('auth-cg')
		}).catch(() => {})
		
	}

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState)
	}

	return (
		<Drawer
			open={isOpen}
			onClose={toggleDrawer}
			direction='right'
			className='px-10 '
			size='80%'
		>
			{/* Content */}
			<div className='profile-info-container my-20 d-flex space-between'>
				<div onClick={()=>navigate('/My-Profile')} className='left-container'>
					{/* Image */}
					<CircularProgress percentage={88} image={userInfo?.profileImage} fullName={userInfo?.fullName}/>
					{/* Name */}
					<h1 className='title-text'>{userInfo?.fullName}</h1>
					{/* Email */}
					<p className='email-text'>{userInfo?.email}</p>
				</div>
				<div className='d-flex column  space-between'>
					{/* Bell icon */}
					<div className='bell-container' onClick={()=>navigate('/NotificationPage')}>
						<PiBellFill />
						<div className='badge'>
							<p>*</p>
						</div>
					</div>
					<div onClick={()=>navigate("/Edit-Profile")} className='edit-container-sd'>
						<PiPencil />
					</div>
				</div>
			</div>
			{/* Drawer Items */}
			<div className='position-relative'>
				{(userInfo?.isGuide ? DRAWER_ITEMS_GUIDE : DRAWER_ITEMS).map(({Icon, name, url, options})=>{
					return(
						<ListItem showBadge={name === 'Support'} key={name} Icon={Icon} name={name} url={url} state={name=== 'More' && showOptions} setState={name === 'More' && setShowOptions} options={options}/>
					)
				})}
				<div onClick={()=>logoutHandler()}>
					<ListItem Icon={PiLockSimpleThin} name={'Logout'} />
				</div>
			</div>


			

		</Drawer>

	)

}

export default SideDrawer
