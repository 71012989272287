import React from 'react'

const Loader = ({marginTop='50%'}) => {
  return (
    <div style={{ marginTop }} className='d-flex column items-center'>
        <div className="loader">
        </div>
        <p className='my-20'>Loading...</p>
    </div>
  )
}

export default Loader