import React from 'react'
import './iconinputdropdown.styles.scss'

const IconInputDropDown = ({Icon, defaultText, iconSize, selectData, ...props}) => {
	return (
		<div className='InputBoxDrawer'>
			{/* icon */}
			{Icon &&
				<Icon size={iconSize || 20} style={{paddingLeft:10}} color='gray' />
			}
			<select {...props} >
				{defaultText &&
					<option>Select {defaultText} </option>
				}
				{selectData?.map((item, index) => {
					return (
						<option key={`${item}-${index}`} value={item.value}>{item.text}</option>
					)
				})}
			</select>
		</div>
	)
}

export default IconInputDropDown
