import React, { useContext } from 'react'
import { identityVerification } from '../../assets/images'
import Wrapper from './Wrapper'
import { PiLightbulb } from 'react-icons/pi'
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { FileUpload, IconInput } from '../../components';
import { StateContext } from '../../context/stateContext';

const IdentityVerification = () => {
  const {identityVerificationForm, setIdentityVerificationForm} = useContext(StateContext)

  const changeHandler = (key, text) => {
    if (text && typeof text === 'object' && text instanceof Blob) {
        setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }
    setIdentityVerificationForm({...identityVerificationForm, [key]:text})
  }


  return (
    <Wrapper image={identityVerification} slideIndex={0} lineLength={3} title='Identity Verification' nextButtonText="Save & Continue" nextButtonUrl='/Profile-Verification/bank-details-verification'>
        <div className='bg-lightgray border-rounded p-20 d-flex space-between items-center'>
            <PiLightbulb size={50} className='me-2'/>
            <p className='fs-12'>These documents are importnat to get a verification Badge in your profile. 
            It may take upto 6 hours to verify.</p>
        </div>
        <h3 className='fw-500 my-20'>Adhaar Card</h3>
        <div className='my-20'>
            <IconInput type='number' value={identityVerificationForm?.adhaarCard} onChange={(e)=>changeHandler('adhaarCard', e.target.value)} Icon={HiOutlineDocumentMagnifyingGlass} placeholder='Adhaar Card' EndIcon={PiLightbulb}/>
        </div>
        <FileUpload value={identityVerificationForm?.adhaarCardFront} onChange={(e)=>changeHandler('adhaarCardFront', e.target.files[0])}/>
        <FileUpload value={identityVerificationForm?.adhaarCardBack} side='Back'  onChange={(e)=>changeHandler('adhaarCardBack', e.target.files[0])}/>
        <div className='d-flex flex-end'>
          <p className='fs-14 me-2'>Add a watermark</p>
          <input type='checkbox' />
        </div>

        <h3 className='fw-500 my-20'>Pan Card</h3>
        <div className='my-20'>
            <IconInput value={identityVerificationForm?.panCard} onChange={(e)=>changeHandler('panCard', e.target.value)} Icon={HiOutlineDocumentMagnifyingGlass} placeholder='Pan Card' EndIcon={PiLightbulb}/>
        </div>
        <FileUpload value={identityVerificationForm?.panCardFront} onChange={(e)=>changeHandler('panCardFront', e.target.files[0])}/>
        <FileUpload value={identityVerificationForm?.panCardBack} side='Back' onChange={(e)=>changeHandler('panCardBack', e.target.files[0])}/>
        <div className='d-flex flex-end'>
          <p className='fs-14 me-2'>Add a watermark</p>
          <input type='checkbox' />
        </div>
        <br/>
    </Wrapper>
  )
}

export default IdentityVerification