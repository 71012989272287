import React, { useState } from 'react'
import { BackButton, ButtonComponent } from '../../components'
import { PiThumbsDown, PiThumbsUp } from 'react-icons/pi'
import './feedback.styles.scss'
import { feedback } from '../../utils/service'
import { notify } from '../../components/Toast'

const Feedback = () => {
  const [level, setLevel] = useState(5)
  const [appExperience, setAppExperience] = useState(undefined)
  const [guides, setGuides] = useState(undefined)
  const [customerService, setCustomerService] = useState(undefined)
  const [comment, setComment] = useState(undefined)

  const submitHandler = () => {
    feedback(comment, appExperience, guides, customerService, level).then(res => {
      notify('Form has been submitted')
      setComment('')
    }).catch(err =>{
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  return (
    <>
      <BackButton size={24} text='Feedback'/>
      <div className='px-20 my-20 feedback-form-container'>
        {/* header : back, title */}

        <h3 className='my-20 fw-600'>Tell us about your experience</h3>
        <div className='d-flex space-between my-20'>
          <p className='text-lightgray'>App experience</p>
          <div>
            <PiThumbsUp onClick={()=>setAppExperience(true)} className={`${appExperience ? 'green-svg' : ''}`}/>
            <PiThumbsDown onClick={()=>setAppExperience(false)} className={`${appExperience === false ? 'red-svg' : ''}`}/>
          </div>
        </div>
        <div className='d-flex space-between my-20'>
          <p className='text-lightgray'>Guides</p>
          <div>
            <PiThumbsUp onClick={()=>setGuides(true)} className={`${guides ? 'green-svg' : ''}`}/>
            <PiThumbsDown onClick={()=>setGuides(false)} className={`${guides === false ? 'red-svg' : ''}`}/>
          </div>
        </div>
        <div className='d-flex space-between my-20'>
          <p className='text-lightgray'>Customer service</p>
          <div>
            <PiThumbsUp onClick={()=>setCustomerService(true)} className={`${customerService ? 'green-svg' : ''}`}/>
            <PiThumbsDown onClick={()=>setCustomerService(false)} className={`${customerService === false ? 'red-svg' : ''}`}/>
          </div>
        </div>

        <p>Comments (optional)</p>
        <textarea value={comment} onChange={(e)=>setComment(e.target.value)} className='textarea'></textarea>

        <p className='fw-600 my-20'>Rate our service</p>
        <div className='d-flex items-center'>
          <div onClick={()=>setLevel(1)} className={`${level >= 1 ? 'filled' : 'unfilled'}`}></div>
          <div className={`${level >= 2 ? 'test-active': 'line'}`} />
          <div onClick={()=>setLevel(2)} className={`${level >= 2 ? 'filled' : 'unfilled'}`}></div>
          <div className={`${level >= 3 ? 'test-active': 'line'}`} />
          <div onClick={()=>setLevel(3)} className={`${level >= 3 ? 'filled' : 'unfilled'}`}></div>
          {/* <div className={`${level >= 4 ? 'test-active': 'line'}`} />
          <div onClick={()=>setLevel(4)} className={`${level >= 4 ? 'filled' : 'unfilled'}`}></div>
          <div className={`${level >= 5 ? 'test-active': 'line'}`} />
          <div onClick={()=>setLevel(5)} className={`${level >= 5 ? 'filled' : 'unfilled'}`}></div> */}
        </div>
        <div className='my-20 text-center'>
          {
            level === 3 ? 
              <p style={{fontSize:'30px'}}>Good  <br/><span style={{fontSize:100}}>🥰</span></p> :
            level === 2 ?
              <p style={{fontSize:'30px'}}>Normal  <br/><span style={{fontSize:100}}>😊</span></p> :
            <p style={{fontSize:'30px'}}>Bad  <br/><span style={{fontSize:100}}>😐</span></p>
          }
        </div>
        <div style={{marginTop:20}}>
          <ButtonComponent onClick={()=>submitHandler()} title='Submit' />
        </div>
          

      </div>
    </>
  )
}

export default Feedback