import React, { useContext, useEffect, useRef, useState } from 'react'
import './chatpage.styles.scss'
import { PiCaretRightBold, PiDotsThreeVerticalBold, PiImage, PiPaperPlaneTilt, PiSpeakerSimpleXLight, PiWarningLight  } from 'react-icons/pi'
import  {MdBlock} from 'react-icons/md'
import { chatBackground, user1 } from '../../assets/images'
import { BackButton, BasicBookingForm, BottomDrawer, ButtonComponent, LineText, ProfileMessage } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { authAxios, chatMetaInformation, convertDateTime, createNotification, getUserActive } from '../../utils/service'
import { notify } from '../../components/Toast'
import { url, websocketurl } from '../../config'
import { StateContext } from '../../context/stateContext'
import constants from '../../constants'

const ChatPage = () => {
  const currentHeight = window.innerHeight
  const { userInfo } = useContext(StateContext)
  const [showModal, setShowModal] = useState(false)
  const [newMessage, setNewMessage] = useState('');
  const [roomName, setRoomName] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false)
  const [messages, setMessages] = useState([])
  const chatSocketRef = useRef(null);  // Use ref to store the WebSocket connection
  const tempSocketRef = useRef(null);  // Use ref to store the WebSocket connection
  const fetchRoomNameApiRef = useRef(null)
  const chatContainerRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { userId, username, image } = location.state || {} 
  const [isActive, setIsActive] = useState(false)
  // Fetch the chat room name based on the user2_id
  const fetchRoomName = async () => {
    const response = await authAxios.post(`${url}/auth/api/create_or_get_chat_room/`, { userId });
    const room_name = response.data.room_name;
    setRoomName(room_name);

    // Initialize WebSocket connection with the room name only if it hasn't been initialized
    if (!chatSocketRef.current) {
        chatSocketRef.current = new WebSocket(`${websocketurl}/ws/chat/${room_name}/`);
        tempSocketRef.current = new WebSocket(`${websocketurl}/ws/general-notifications/${userId}/`)
        // Handle incoming WebSocket messages
        chatSocketRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, data]);
        };
        // Handle WebSocket closure
        chatSocketRef.current.onclose = () => {
            console.log("disconnected.");
        };
        tempSocketRef.current.onclose = () => {
            console.log("disconnected.");
        };
    }
  };

  // Fetch all previous messages
  const fetchMessages = async () => {
      if (roomName) {
          const response = await authAxios.get(`${url}/auth/api/messages/${roomName}/`);
          setMessages(response.data);
      }
  };

  // Send new message via WebSocket
  const sendMessage = () => {
      if (chatSocketRef.current && newMessage.trim()) {
          chatSocketRef.current.send(JSON.stringify({
              'message': newMessage,
              'sender':userInfo?.username,
              'chatRoom':roomName
          }));
          if(tempSocketRef.current){
            tempSocketRef.current.send(JSON.stringify({
              "message":'new message from '+userInfo?.username,
              "eventType":constants.message
            }))
          }
          setNewMessage(''); // Clear the input after sending
          scrollToBottom()
      }
  };

  const notificationHandler = (message) => {
    createNotification(username, `${userInfo?.fullName} ${message}`).then(res =>{
      notify('notification sent to the user.', 'black', 'top-center')
      setShowModal(false)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message, 'black', 'top-center')
    })
  }

  useEffect(() => {
    if (userId && !fetchRoomNameApiRef.current) {
        // Fetch the room name and messages when the component loads
        fetchRoomName();
        fetchRoomNameApiRef.current = true
        getUserActive(userId).then(res=>{
          setIsActive(res.data.status)
        }).catch(err => {
          notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }
    return () => {
      if(chatSocketRef.current){
        chatSocketRef.current.close()
        tempSocketRef.current.close()
        chatMetaInformation()
      }
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (roomName) {
        fetchMessages();
    }
    // eslint-disable-next-line
  }, [roomName]);


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }
  
  useEffect(() => {
    scrollToBottom()
  }, [messages]); 

  useEffect(()=>{
    return()=>{
      setShowModal(false)
    }
  },[])

  return (
    <>
      <div ref={chatContainerRef} id='scroll' style={{maxHeight:currentHeight, overflowY:'scroll', backgroundImage:`linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.5)), url(${chatBackground})`, minHeight:currentHeight}}>
        {/* Header Container */}
        <div className='py-10 d-flex space-between items-center header-container-cp'>
            {/* back , image and badge */}
            <div className='d-flex items-center image-container-cp'>
              {/* Back chevron */}
              <BackButton size={24} text='' style={{margin:-10}}/>

              <div onClick={()=>navigate('/My-Profile')} className='position-relative'>
                {/* Profile */}
                <img src={image} alt='user name 1' />
              </div>

              {/* name, active/inactive status text */}
              <div onClick={()=>navigate('/My-Profile')} className='d-flex column information-container-cp'>
                <div className='d-flex items-center'>
                  <p>{username}</p>
                  <PiCaretRightBold color='gray' size={12}/>
                </div>
                  {/* active status [online/offline] */}
                  <p className='status-text'>{isActive ? 'online': 'offline'}</p>
              </div>
            </div>

            <div className='d-flex items-center position-relative'>
              {/* Book/Calendar button */}
              <ButtonComponent onClick={()=>setShowDrawer(!showDrawer)} title='Book' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right, white, white)", padding:'5px 10px', marginRight:10}} style={{color:"var(--primary)", fontSize:14}} />

              {/* Three vertical dots */}
              <PiDotsThreeVerticalBold size={30} onClick={()=>setShowModal(!showModal)}/>
              
              <div className={`chat-settings-modal ${showModal ? 'active' : "hide"}`}>
                  <div className='d-flex items-center my-10 px-10'>
                    <PiSpeakerSimpleXLight />
                    <p>Mute</p>
                  </div>
                  <div onClick={()=>notificationHandler('Reported your profile.')} className='d-flex items-center my-10 px-10 cursor-pointer'>
                    <PiWarningLight color='red'/>
                    <p style={{color:"red"}}>Report</p>
                  </div>
                  <div className='d-flex items-center my-10 px-10'>
                    <MdBlock />
                    <p>Block</p>
                  </div>
                 
              </div>
            </div>
        </div>
        <div className='d-flex w-100'>
          <div className='w-100' style={{marginBottom:80}}>
              {messages.map((item, index) => {
                const self = item.sender !== userInfo?.username
                const {formattedTime, formattedDate} = convertDateTime(item.timestamp)
                const shouldShowDateSeparator = index === 0 || (messages[index - 1] && convertDateTime(messages[index - 1].timestamp).formattedDate !== formattedDate);

                return(
                  <React.Fragment key={index}>
                     {shouldShowDateSeparator && (
                      <LineText text={formattedDate}/>
                    )}

                    <ProfileMessage 
                      // image={item.profileImage} 
                      message={item.content}
                      date={formattedDate}
                      time={formattedTime}
                      self={self}
                    />
                  </React.Fragment>
                )
              })}
          </div>
          <div className='d-flex items-center input-container-cp'>
            <input placeholder='Type your message...' value={newMessage} onChange={(e)=>setNewMessage(e.target.value)}/>
            <PiImage style={{position:"absolute", background:"var(--primary)", color:"white", height:"26px", marginLeft:-4, width:"26px", borderRadius:'50%', padding:"5px"}} color='var(--primary)' />
            <div onClick={()=>sendMessage()}  className='d-flex column items-center space-evenly' style={{background:"white", height:'30px', width:"60px", padding:"4px", borderRadius:"56px", marginRight:5}}>
              <PiPaperPlaneTilt size={26} color='var(--primary)' />
            </div>
          </div>
        </div>
      </div>
      <BottomDrawer
          state={showDrawer}
          content={
                  <BasicBookingForm
                    guideUsername={username}
                    image={user1}
                    fullName={'Arijit Singh'}
                    onClick={()=>setShowDrawer(!showDrawer)}
                    user1_id={userInfo?.id}  
                    user1_username={userInfo?.username}
                    guideId={userId}
                  />}
          yValue={40}
        />
    </>

  )
}

export default ChatPage