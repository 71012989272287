import React, { useContext, useState } from 'react'
import { IconInput } from '../../components'
import { wizard3 } from '../../assets/images'
import { PiPassword, PiUser, PiWhatsappLogo } from 'react-icons/pi'
import Wrapper from './Wrapper'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { StateContext } from '../../context/stateContext'

const PersonalizeAccount = () => {
    const [showPassword, setShowPassword] = useState(false)
    const {userForm, setUserForm} = useContext(StateContext)
    const [isChecked, setIsChecked] = useState(false)

    const checkHandler = () => {
        setUserForm({...userForm, whatsappNumber:!isChecked ? userForm?.contact : ''})
        setIsChecked(!isChecked)
    }

    return (
      <Wrapper image={wizard3} slideIndex={3} title='Personalize Account' nextButtonText="Let's Explore" nextButtonUrl='/Home' submit={true}>
          {/* Input Fields [ username, password, whatsapp number ] */}
          <IconInput value={userForm?.username} onChange={(e)=>setUserForm({...userForm, 'username':e.target.value})} Icon={PiUser } iconSize={30} placeholder='Username' />
          <IconInput value={userForm?.password} onChange={(e)=>setUserForm({...userForm, 'password':e.target.value} )} Icon={PiPassword } type={`${showPassword ? 'text' : 'password'}`} iconSize={30} placeholder='Set Password' EndIcon={ showPassword ? BsEye : BsEyeSlash } endIconFunction={()=>setShowPassword(!showPassword)} />
          <IconInput disabled={isChecked} value={userForm?.whatsappNumber} onChange={(e)=>setUserForm({...userForm, 'whatsappNumber':e.target.value})} Icon={PiWhatsappLogo } iconSize={30} placeholder='Whatsapp Number' />

          {/* checkbox [ age ]  */}
          <div className='d-flex items-center flex-end' style={{margin:"-15px -10px 0px 0px"}}>
              <input onChange={()=>checkHandler()} type='checkbox' style={{margin:'10px 2px 10px 0px', height:14, width:14}} />
              <p style={{fontSize:13, color:'var(--gray)'}}>Same as contact number.</p>
          </div>
          
          {/* checkbox [ age ]  */}
          <div className='d-flex items-center'>
              <input type='checkbox' style={{margin:'10px 10px 10px 0px', height:17, width:17}} />
              <p style={{fontSize:13, color:'var(--gray)'}}>Yes, I'm 18 years of age or older.</p>
          </div>
        
          {/* checkbox [ T&C ]  */}
          <div className='d-flex items-center'>
              <input type='checkbox' style={{margin:'10px 10px 10px 0px', height:17, width:17}} />
              <p style={{fontSize:13, color:'var(--gray)'}}>I agree to the Terms of Service and Privacy Policy.</p>
          </div>
          <br/>
      </Wrapper>
    )
}

export default PersonalizeAccount