import React, { useContext, useState } from 'react'
import { BackButton, ButtonComponent, IconInput } from '../../components'
import './changeresetpassword.styles.scss'
import { PiEye, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { changePasswordFunction } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { notify } from '../../components/Toast'

const ChangeResetPasswordPage = () => {
  const navigate = useNavigate()
  const { userInfo } = useContext(StateContext)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [showCurrentPassword, setShowCurrentPassword ] = useState(false)
  const [showNewPassword, setShowNewPassword ] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  
  const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  

    const updatePassword = () => {
      if (currentPassword.length === 0 || newPassword.length === 0 || repeatNewPassword.length === 0) {
        alert('Please fill in all password fields');
        return;
      }
  
      if (currentPassword === newPassword) {
        alert('Your new password cannot be the same as the previous password');
        return;
      }
  
      if (!PASSWORD_REGEX.test(newPassword)) {
        alert('Your new password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.');
        return;
      }
  
      if (newPassword !== repeatNewPassword) {
        alert('The new passwords do not match');
        return;
      }

      changePasswordFunction(userInfo?.id, currentPassword, newPassword).then(res => {
        notify('password updated !')
      }).catch(error => {
        alert(error.response && error.response.data.detail ? error.response.data.detail : error.message)
      })
  
    };


  return (
    <div className=''>
        <BackButton size={24} text='Change Password'/>
        <p className='help-text'>Your new password must be different from previously used password.</p>
        <div className='px-10'>
          <div className='px-10'>
            <IconInput endIconFunction={()=>setShowCurrentPassword(!showCurrentPassword)} placeholder='Current Password' EndIcon={showCurrentPassword ? PiEye : PiEyeSlash} type={showCurrentPassword ? 'text' : 'password'} value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)} />
            <IconInput endIconFunction={()=>setShowNewPassword(!showNewPassword)} placeholder='New Password' EndIcon={showNewPassword ? PiEye : PiEyeSlash} type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} />
            <p className='help-text' style={{fontSize:12, marginTop:-7, marginLeft:2}}>must be atleast 8 charachters</p>
            <IconInput endIconFunction={()=>setShowConfirmPassword(!showConfirmPassword)} placeholder='Confirm Password' EndIcon={showConfirmPassword ? PiEye : PiEyeSlash} type={showConfirmPassword ? 'text' : 'password'} value={repeatNewPassword} onChange={(e)=>setRepeatNewPassword(e.target.value)} />
          <p className='help-text' style={{fontSize:12, marginTop:-7, marginLeft:2}}>both passwords must match.</p>
          </div>
        </div>
        <ButtonComponent title='Change Password' onClick={()=>updatePassword()} wrapperContainerStyle={{margin:"10px 20px"}} />
        <p className='help-text text-center'>Forgot Password ? <span onClick={()=>navigate('/Forgot-Password')}>Reset Password.</span></p>
    </div>
  )
}

export default ChangeResetPasswordPage