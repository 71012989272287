import React, { useContext } from 'react'
import { createOrder } from '../../utils/service'
import { notify } from '../Toast'
import { StateContext } from '../../context/stateContext';
import { useNavigate } from 'react-router-dom';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const RazorpayComponent = ({id, setSelected}) => {
    const {userInfo} = useContext(StateContext)
    const navigate = useNavigate()
    async function loadRazorpayModal(amount, orderId) {
    
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
    
        if (!res) {
          alert(
            "Razorpay failed to load. make sure you have active internet connection"
          );
          return;
        }
    
        const options = {
          key_id: process.env.REACT_APP_RAZORPAY_KEY,
          order_id: orderId,
          name: "City2Guide",
          description: "Thank you for Shopping With us.",
          image: "https://iconape.com/wp-content/files/fy/347885/png/347885.png",
          handler: function (response) {
            const sendData = async () => {
              // handle api call or any other logic here
              navigate('/Home')
              setSelected(undefined)
            };
            sendData();
          },
          prefill: {
            name: userInfo.fullName,
            email: userInfo.email,
            contact: userInfo.contact,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    
    const handleCreateOrder = ()=>{
        if(id !== null || id !== undefined){
            createOrder(id).then(res => {
                loadRazorpayModal(res.data.otherData.amount, res.data.order_id)
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })
        }
    }
  
  return (
    <div>
        <button id='razorpay-btn' className='p-10 bg-primary text-whitesmoke border-rounded border shadow' onClick={()=>handleCreateOrder()}>Purchase</button>
    </div>
  )
}

export default RazorpayComponent