import React from 'react'
import './chatlist.styles.scss'
import { useNavigate } from 'react-router-dom'
import { IoCheckmark } from 'react-icons/io5'
import { url } from '../../config'
import { chatMetaInformation, convertDateTime } from '../../utils/service'

const ChatList = ({userId, username, fullName, image, message, lastMessageFrom, isActive, isRead, unreadCount, timestamp}) => {
    const navigate = useNavigate()
    const clickHandler = () => {
        chatMetaInformation()
        navigate('/Chat', {state:{userId, image:url + image, fullName, username}})
    }
    const {formattedTime} = convertDateTime(timestamp)
    message = message.length > 40 ? message.slice(0, 40) + '...': message
    return (
        <div className='chatlist-container'>
            <div onClick={()=>clickHandler()} className='d-flex'>
                <div className='position-relative'>
                    <img src={url + image} alt={username} />
                    {/* Badge */}
                    <span className={`${isActive ? 'active-badge' : 'inactive-badge'} `}/>
                </div>

                <div className='d-flex column mt-5'>
                    <p>{fullName}</p>
                    <div className='d-flex items-center'>
                        {lastMessageFrom === username ? '' : <IoCheckmark size={16} color='gray' style={{marginLeft:10, marginRight:-8}}/>}
                        <span dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br />') }}></span>
                    </div>
                    
                </div>
            </div>
            <div className='d-flex items-center column'>
                {!isRead && unreadCount !== 0 && <p className='badge'>{unreadCount}</p>}
                <span>{formattedTime}</span>
            </div>
                
            
        </div>
    )
}

export default ChatList