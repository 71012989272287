import React from 'react'
import './dividercomponent.styles.scss';


const DividerComponent = ({ text }) => {
  return (
    <div className="divider">
      <span className="divider-text">{text}</span>
    </div>
  );
}

export default DividerComponent