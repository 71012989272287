import React, { useEffect, useState } from 'react'
import './guideslist.styles.scss'
import { notifyMe } from '../../assets/images'
import { BackButton, ButtonComponent, ImageCard } from '../../components'
import { useLocation } from 'react-router-dom'
import { checkIsNew, guideBookmarkStatus, guides, notifyMeFunction } from '../../utils/service'
import { notify } from '../../components/Toast'
import { parseISO, differenceInYears } from 'date-fns';


const CUSTOM_IMAGE_CARD_STYLE = {height:"271px", margin:5, padding:5,}

const GuidesListPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const location = useLocation();
  const { stateName } = location.state || {};

  const notifyHandler = () => {
    notifyMeFunction(stateName).then(res => {
      notify('You will be notified soon !')
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  useEffect(()=>{
    if(stateName){
      guides(stateName).then(res => {
        setData(res.data)
      }).catch(err => {
        notify(err.response && err.response.data.details ? err.response.data.details : err.message)
      }).finally(()=>{
        setLoading(false)
      })
    }
  },[stateName])

  return (
    <div className='my-10 guides-list-container'>
      <BackButton size={24} text='Back'/>
      {data.length === 0 && !loading?
        <div className='px-20'>
          {/* Be the first : text */}
          <h1 className='text-center my-20' style={{fontWeight:600}}>Be the first to<br/> explore {stateName} ! </h1>

          <p className='text-center'>Once guide is available we will notify you on your email.</p>

          {/* Notify me : button */}
          <ButtonComponent onClick={()=>notifyHandler()} wrapperContainerStyle={{marginTop:100}} title='Notify Me'/>

          {/* Image */}
          <img className='my-20' src={notifyMe} alt='Notify me'/>

        </div>
        :
        <>
          <div className='header-container'>
            <h1 className='header-title'>Guides <span>in {stateName}</span></h1>
          </div>
          <div className='d-flex flex-wrap space-evenly'>
              {data.map((item, index) => {
                  const age = differenceInYears(new Date(), parseISO(item.dob))
                  return(
                      <ImageCard
                        _id={item.id}
                        isNew={checkIsNew(item.createdAt)}
                        redirectText='/Guide-Details'
                        metaRedirectText={{"id":item.id}}
                        key={`${item.userDetails.fullName}-${index}`}
                        guideName={item.userDetails.fullName}
                        guideAge={age}
                        activeStatus={item.isActive || false}
                        bookmarked={item.bookmarked}
                        bookmarkFunction={guideBookmarkStatus}
                        alt={item.guideName}
                        style={{...CUSTOM_IMAGE_CARD_STYLE, backgroundImage:`url(${item.profileImage})`}}
                        guidesChipsData={item?.interest?.split(',')}/>
                  )
              })}
          </div>
        </>
      }
    </div>
  )
}

export default GuidesListPage