import React from 'react'
import './linetext.styles.scss'

const index = ({text, ...props}) => {
  return (
    <div className='line-text-container'>
        <hr {...props}/>
        <p>{text}</p>
        <hr {...props}/>
    </div>
  )
}

export default index