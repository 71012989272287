import React, { useContext, useEffect } from 'react'
import { IndiaGate } from '../../assets/images'
import './welcomepage.styles.scss'
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '../../components';
import { StateContext } from '../../context/stateContext';

const WelcomePage = () => {
  const {isTourist, setIsTourist, userInfo} = useContext(StateContext)

  const handleClick = (type) => {
      setIsTourist(type === 'tourist');
  };
  
  const navigate = useNavigate()

  useEffect(()=>{
    if(userInfo){
      navigate('/Home')
    }
    // eslint-disable-next-line
  },[])


  return (
    <div className="background-image">
        <div className='image-container-wp'>
            <img src={IndiaGate} alt="India Gate" />
        </div>
        <div className="content">
            <h1>Start your journey <br/>with <span className="highlight">City2Guide</span></h1>
            <p>Discover Breathtaking destinations, book guides & explore the Incredible India like never before.</p>
            <div className="button-container">
                <div className={`slider ${isTourist ? 'left' : 'right'}`}></div>
                <button className="toggle-button" style={{color:`${isTourist ? 'white' :'black' }`}} onClick={() => handleClick('tourist')}><span>Tourist</span></button>
                <button className="toggle-button" style={{color:`${!isTourist ? 'white' :'black' }`}} onClick={() => handleClick('guide')}>Guide</button>
            </div>
            <ButtonComponent title='Get Started' onClick={()=>navigate("/Login")} />
        </div>
    </div>
  );
}

export default WelcomePage