import React, { useState } from 'react'
import { formatDistance, subDays } from 'date-fns'
import OtpComponent from '../OtpComponent'
import './userimagecard.styles.scss'
import ButtonComponent from '../Button/ButtonComponent'

const UserImageCard = ({image, fullName, id, created, status, email, showModal, setShowModal, onOpenModal, isVerified, handleOtpSend, cancelHandler, booktext, onClick}) => {
  const [state, setState] = useState({
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:''
  })
  const [showText, setShowText] = useState(false)
  const [reasonText, setReasonText] = useState('')
  const statusMessage = status === null ? 'pending' : status ? 'accepted' : 'cancelled'


  return (
    <>
    <div className='d-flex content-center items-center relative'>
      <div className={`modal-container-uic ${showModal ? '' : 'hide'}`}>
        {showText ?
        <>
          <textarea placeholder='Type here...' value={reasonText} onChange={(e)=>setReasonText(e.target.value)} className='textarea-image-card' rows={8}></textarea>
          <div className='d-flex w-100 space-between'>
            <ButtonComponent onClick={()=>setShowText(false)} buttonTextColor='black' wrapperContainerStyle={{width:"100%", margin:"10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} title='Cancel' />
            <ButtonComponent onClick={()=>{cancelHandler(reasonText);setShowText(false);setReasonText('')}} wrapperContainerStyle={{width:"100%", margin:"10px"}} title='Send' />
          </div>
        </>
        :
        <>
          <p className='text-center fw-500'>Please enter OTP shared by tourist.</p>
          <OtpComponent state={state} setState={setState}/>
          <div className='d-flex w-100 space-between'>
            <ButtonComponent onClick={()=>setShowModal(false)} buttonTextColor='black' wrapperContainerStyle={{width:"100%", margin:"10px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} title='Cancel' />
            <ButtonComponent onClick={()=>handleOtpSend(state)} wrapperContainerStyle={{width:"100%", margin:"10px"}} title='Send' />
          </div>
          <p className='text-end fw-500 mt-4 px-10 text-lightgray' onClick={()=>setShowText(true)}>Cancel Tour ? Click Here.</p>
        </>
        }
      </div>
    </div>
    <div className='d-flex cursor-pointer space-between items-center p-10 relative'>
        <div onClick={onClick ? ()=>onClick(): !isVerified && statusMessage !== 'cancelled' ? () => onOpenModal(id) : ()=>true} className='d-flex items-center'>
          <div className='d-flex'>
              <img style={{height:50, width:50, borderRadius:"50%", marginRight:20, objectFit:"cover"}} src={image} alt={fullName} />
          </div>
          <div className='d-flex column'>
              <p className='fw-600 fs-20'>{fullName}</p>
              {created &&
                <p className='fw-400 fs-12 text-lightgray'>{booktext} {formatDistance(subDays(created, 0), new Date(), { addSuffix: true })}</p>
              }
              {email &&
                <p className='fw-400 fs-12'>{email}</p>
              }

          </div>
        </div>
        <p className={statusMessage}>{statusMessage}</p>
    </div>
    </>
  )
}

export default UserImageCard