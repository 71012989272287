import React, { useContext } from 'react'
import { BsX } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../../components'
import { createGuide, storeIdentityVerification } from '../../utils/service'
import { notify } from '../../components/Toast'
import { StateContext } from '../../context/stateContext'

const Wrapper = ({image, slideIndex, title, children, descText, nextButtonText, nextButtonUrl, identityHandler, submit=false, lineLength=4}) => {
  const navigate = useNavigate()
  const {userForm, identityVerificationForm} = useContext(StateContext)
  const submitHandler = () => {
    if(!submit){
      return navigate(nextButtonUrl)
    }

    if(identityHandler){
      storeIdentityVerification(identityVerificationForm).then(res => {
        notify('Account Created 🚀, Redirecting...')
        setTimeout(()=>{
          navigate(nextButtonUrl)
        }, 2000)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
      return
    }

    notify('also make sure all the validations are added for password and all required fields are filled.')
    if(userForm?.username.length < 5 || userForm?.password.length < 5){
      return notify('please fill the valid username and password.')
    }
    createGuide(userForm).then(res => {
      notify('Account Created 🚀, Redirecting...')
      setTimeout(()=>{
        navigate(nextButtonUrl)
      }, 2000)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  return (
    <div className='contact-verification-container'>
        
    {/* img */}
    <img className='contact-card' src={image} alt='Personalize your account' />

    {/* close icon */}
    <BsX onClick={()=>navigate('/Signup')} className='close-icon'/>
    
    {/* sliding lines */}
    <div className='sliding-lines-container'>
        {Array.from(Array(lineLength).keys()).map(number => {
            return(
                <div key={number} className='sliding-line'>
                    {number < slideIndex ?
                        <span className='active' style={{width:'100%'}} />
                        :
                        <span className='active' style={{width: number === slideIndex ? '10%' : '0%'}} />
                    }
                </div>
            )
        })}
    </div>

    {/* Personal Information : text */}
    <h1 className='verification-text'>{title}</h1>
    {descText &&
      <p className='desc-text'>{descText}</p>
    }
    {children}
    

    {/* back & save and continue buttton */}
    <div className='back-save-buttons-container'>
      <ButtonComponent onClick={()=>navigate(-1)} title={'Back'} wrapperContainerStyle={{width:"35%", backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)", opacity:0.9}} buttonTextColor='black' />
      <ButtonComponent onClick={()=>submitHandler()} title={nextButtonText} wrapperContainerStyle={{width:"45%"}}/>
    </div>

  </div>
  )
}

export default Wrapper