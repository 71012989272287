import React from 'react'
import './settingspage.styles.scss'
import { PiDeviceMobile, PiGear, PiPassword, PiUser, PiUserCircleMinus, PiUserGear } from 'react-icons/pi'
import { CgAttachment } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '../../components'
import { IoColorPaletteOutline } from 'react-icons/io5'

const SETTINGS_CONTENT = [
  {id:1, name:'Add links', icon:CgAttachment , url:'Add-Links'},
  {id:2, name:'Change theme', icon:IoColorPaletteOutline , url:'Change-Theme'},
  {id:3, name:'Update Username', icon:PiUser, url:'Update-Username'},
  {id:4, name:'Update Contact details', icon:PiDeviceMobile, url:'Update-Contact'},
  {id:5, name:'Change or reset Password', icon:PiPassword, url:'Change-Reset-Password'},
  {id:6, name:'Delete or Deactivate account', icon:PiUserCircleMinus, url:''},
  {id:7, name:'Unblock Profiles', icon:PiUserGear, url:'Unblock-Profiles'}
]

const SettingsContentRenderer = ({Icon, name, url}) => {

  const navigate = useNavigate()

  return (
    <div  className='cursor-pointer settings-content d-flex items-center' onClick={url?.length > 0 ? ()=>navigate('/'+url) : ()=> true}>
      <Icon color='gray' />
      <p>{name}</p>
    </div>
  )
}

const SettingsPage = () => {
  
  return (
    <>
      <BackButton size={24} />

      <div className='settings-page-container d-flex items-center px-20'>
        <PiGear size={30} />
        <h1 className='px-10' style={{fontWeight:500}}>Account Settings</h1>
      </div>
      <div className='d-flex column'>
        {SETTINGS_CONTENT.map(item => (
          <SettingsContentRenderer Icon={item.icon} name={item.name} url={item.url}/>
        ))}
      </div>
    </>
  )
}

export default SettingsPage