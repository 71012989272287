import React, { useEffect, useState } from 'react';
import './calendar.styles.scss';
import { BackButton, UserImageCard } from '../../components';
import { getEventsDates, getFormattedDate } from '../../utils/service';
import { notify } from '../../components/Toast';

const CalendarPage = () => {
  
  const [currentDate, setCurrentDate] = useState(new Date());
  const [datesData, setDatesData] = useState([])
  const [eventData, setEventData] = useState([])

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);
    const days = [];

    // Fill in the blanks before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty"></div>);
    }

    // Add the actual days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const event = datesData.find(item => item.date === date);
      days.push(
        <div
          onClick={event ? ()=> setCurrentDate(new Date(year, month, day)) : ()=> true}
          key={day}
          className={`day ${event ? 'event' : ''}`}
        >
          <span>{day}</span>
          {event && <div className="event-text">{event.notificationRef}</div>}
        </div>
      );
    }
    return days;
  };

  const navigateMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const navigateYear = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setFullYear(newDate.getFullYear() + direction);
    setCurrentDate(newDate);
  };

  useEffect(()=>{
      getEventsDates(new Date().getMonth()+1, new Date().getFullYear()).then(res => {
        setDatesData(res.data)
        const todaysDate = getFormattedDate(new Date())
        const todaysEvent = res.data?.filter(item => item.date === todaysDate)
        setEventData(todaysEvent)
      }).catch(err =>{
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
  },[])

  useEffect(()=>{
    const todaysDate = getFormattedDate(currentDate)
    const todaysEvent = datesData?.filter(item => item.date === todaysDate)
    setEventData(todaysEvent)
    //eslint-disable-next-line
  }, [currentDate])

  return (
    <>
      <BackButton text='Calendar'/>
      <div className='d-flex content-center'>
          <div className="calendar">
          <div className="header">
              <button onClick={() => navigateYear(-1)}>&laquo;</button>
              <button onClick={() => navigateMonth(-1)}>&lsaquo;</button>
              <span>{months[currentDate.getMonth()]} {currentDate.getFullYear()}</span>
              <button onClick={() => navigateMonth(1)}>&rsaquo;</button>
              <button onClick={() => navigateYear(1)}>&raquo;</button>
          </div>
          <div className="days-of-week">
              {daysOfWeek.map((day) => (
              <div key={day} className="day-name">{day}</div>
              ))}
          </div>
          <div className="days">
              {renderDays()}
          </div>
          </div>
      </div>
      <div className='px-10'>
        <div className='p-10 bg-lightgray border-rounded' style={{marginTop:20}}>
        <p className='fw-600'>Today's Event</p>
        {eventData.map((item) => {
          return(
            <UserImageCard key={item._id} isVerified={true} status={item.status} image={item.profileImage} fullName={item.user} created={item.createdAt} booktext='Booked'/>
          )
        })}
        </div>
      </div>
    </>
  );
};

export default CalendarPage;
