import React, { useState } from 'react';
import './toggle.styles.scss'; 

const Toggle = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="toggle-container" onClick={handleToggle}>
      <div className={`toggle ${isToggled ? 'toggled' : ''}`}>
        <div className="toggle-circle"></div>
      </div>
    </div>
  );
};

export default Toggle;
