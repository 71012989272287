import React, { useEffect, useState } from 'react'
import { BackButton, Card } from '../../components'
import { memberships } from '../../utils/service'
import { notify } from '../../components/Toast'

const MembershipPage = () => {
  const [selected, setSelected] = useState(undefined)
  const [data, setData] = useState([])
  useEffect(()=>{
    memberships().then(res => {
      setData(res.data)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  },[])

  return (
    <div className='px-5'>
      <BackButton size={24} text='Membership' />
      {data.map((item, index) => {
        return(
          <Card key={`${index}-${item.title}`} title={item.title} desc={item.desc} price={item.price} index={index} selected={selected === index} setSelected={setSelected} />
        )
      })}
    </div>
  )
}

export default MembershipPage