import React, { useState } from 'react'
import { BackButton } from '../../components'
import { PiCaretRight, PiMapPin, PiMapPinFill, PiPaperPlane, PiPaperPlaneFill, PiPhone } from 'react-icons/pi'
import { CiMail } from 'react-icons/ci'
import { location } from '../../assets/images'
import {Facebook, Instagram, Linkedin, Youtube} from '../../assets/icons'

const IconText = ({Icon, text}) => {
  return(
    <div className='my-10 border d-flex space-between items-center border-rounded p-10'>
      <div className='d-flex items-center'>
        <Icon size={22}/>
        <p className='px-10'>{text}</p>
      </div>
      <PiCaretRight />
    </div>
  )
}

const ContactUsPage = () => {
  const [showMap, setShowMap] = useState(false)
  return (
    <div className='my-20 px-10 position-relative'>
      {/* header : back, title */}
      <div className='d-flex space-between items-center'>
        <BackButton size={24}/>
      </div>
      <div className='d-flex space-between items-center my-20'>
        <div onClick={()=>setShowMap(!showMap)} className={`d-flex content-center items-center shadow`} style={{background:"#ece8e8", height:40, width:40,  marginBottom:showMap ? -60 : 0, borderRadius:"30%",}}>
          {showMap ? 
          <PiPaperPlaneFill color={`var(--primary)`} style={{transform:"rotate(48deg)", marginLeft:6, marginBottom:6}} size={30}/>
          :
          <PiPaperPlane color={`black`} style={{transform:"rotate(48deg)", marginLeft:6, marginBottom:6}} size={28}/>
          }
          
        </div>
        <div onClick={()=>setShowMap(!showMap)} className={`d-flex content-center items-center shadow`} style={{background:"#ece8e8", height:40, width:40, marginBottom:showMap ? 0 : -60, borderRadius:"30%",}}>
          {showMap ?
            <PiMapPin color={`black`} size={28}/>
            :
            <PiMapPinFill color={`var(--primary)`} size={30}/>

          }
        </div>
      </div>
      <div className={`bg-lightgray p-10 border-rounded ${!showMap ? 'hide' :''}`}>
        <h2 className='fw-500'>Get In Touch</h2>
        <p>Call us on our 24x7 active toll free numbers</p>

        <IconText Icon={PiPhone} text='91XXXXXXXXX'/>
        <IconText Icon={PiPhone} text='91XXXXXXXXX'/>
        <IconText Icon={PiPhone} text='91XXXXXXXXX'/>

        <p className='my-20 px-10'>Write to us and get guaranteed response within 24 hours</p>

        <IconText Icon={CiMail} text='support@city2guide.com'/>
        <IconText Icon={CiMail} text='support@city2guide.com'/>

      </div>
      <div className={`bg-lightgray p-10 border-rounded ${!showMap ? '' :'hide'}`}>
        <h2 className='fw-500'>Reach to Us</h2>
        <img style={{width:"100%"}} src={location} alt='address' />
      </div>

      <div className='d-flex p-10 my-20 items-center space-between bg-lightgray border-rounded shadow' style={{position: 'fixed', bottom: '30px', width: '90%'}}>
          <img style={{height:40, width:40, borderRadius:'50%'}} src={Instagram} alt='Instagram' />
          <img style={{height:40, width:40, borderRadius:'50%'}} src={Linkedin} alt='Linkedin' />
          <img style={{height:40, width:40, borderRadius:'50%'}} src={Youtube} alt='Youtube' />
          <img style={{height:40, width:40, borderRadius:'50%'}} src={Facebook} alt='facebook' />
      </div>

    </div>
  )
}

export default ContactUsPage