import React, { useContext, useEffect, useState } from 'react'
import './guidedetailspage.styles.scss'
import { PiArrowRight, PiArticleThin, PiBook, PiBookmarkSimple, PiCircleFill, PiClockCountdownThin, PiCurrencyDollarFill, PiCurrencyEur, PiCurrencyInrFill, PiDotsThreeVerticalBold, PiFootball, PiGameController, PiGenderFemale, PiGenderMale, PiInfo, PiInstagramLogo, PiLightbulbFilament, PiLinkedinLogo, PiMapPin, PiMoney, PiPaperPlaneTiltFill, PiPhone, PiSuitcaseFill, PiTwitterLogoFill, PiUser, PiUserCheck, PiWallet, PiWarning, PiWhatsappLogoFill, PiX } from 'react-icons/pi'
import { IoEarthOutline } from 'react-icons/io5'
import { LiaHandPointRightSolid  } from 'react-icons/lia'
import { AiOutlineStop } from 'react-icons/ai'
import { TbPhoneCheck } from 'react-icons/tb'
import { Accordion, BackButton, BasicBookingForm, BottomDrawer, ButtonComponent, ModalContentContainer, Rating } from '../../components'
import { Facebook, Instagram, Linkedin, Youtube } from '../../assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { animated, useSpring } from '@react-spring/web'
import { notify } from '../../components/Toast'
import { blockUser, checkIsNew, createNotification, getSocialMediaLinks, guideBookmarkStatus, guides } from '../../utils/service'
import { differenceInYears, parseISO } from 'date-fns'
import { StateContext } from '../../context/stateContext'
import { design } from '../../assets/images'

const ChargesContainer = ({selected, setSelected, hoursText, price}) => {
  return(
    <div onClick={()=>setSelected(hoursText)} className={`cursor-pointer charges-container ${selected === hoursText ? 'selected': ''}`}>
      <div className='mini-container'>
        <PiClockCountdownThin size={60}  />
        <p className='hours-text'>{hoursText}</p>
      </div>
      <p className='text-center hour-text-1'>{hoursText?.toLowerCase()?.split('h')[0]} Hours</p>
      <p className='price-text'>INR ₹{price}</p>
    </div>
  )
}

const LanguageBoxRenderer = ({language, efficiency}) => {
  return(
    <div className='lang-container'>
      {/* based on the langauge name we will render the letter here , we will need maybe dict or something */}
      <p className='first-letter'>{language?.slice(0, 1)}</p>
      <p className='lang'>{language}</p>
      <div className='badge'>
        <span className={`${efficiency}`}>{efficiency}</span>
      </div>
    </div>
  )
}

const InformationContainer = ({Icon, Icon1, title, children}) => {
  return (
      <>
        <hr />
        <div className='my-15'>

          <div className='d-flex items-center'>
              <Icon size={20}/>
              {Icon1 &&
                <Icon1 size={20}/>
              }
              <h1 className='title-text px-10'>{title}</h1>
          </div>  

          {children}
        </div>
      </>
  )
}

const GuideDetailsPage = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [contactRequested, setContactRequested] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [guideDetail, setGuideDetail] = useState({})
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedCharge, setSelectedCharge] = useState('')
  const { id } = location.state || {};
  const {userInfo} = useContext(StateContext)

  const slideAnimation = useSpring({
    transform: !showModal ? 'translateY(5%)' : 'translateY(0%)',
    config: { tension: 100, friction: 10 },
  });

  let timer;
  const handleCloseModal = () => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      if(showModal){
        setShowModal(false)
      }
    }, 50)
  }

  const notificationHandler = (message) => {
    createNotification(guideDetail?.userDetails?.username, `${userInfo?.fullName} ${message}`).then(res =>{
      notify('notification sent to the user.')
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }
  
  const [linkInputs, setLinkInputs] = useState({
      'Facebook':'',
      'Instagram':'',
      'LinkedIn':'',
      'Youtube':'',
      'Pinterest':'',
      'Twitter':''
  })

  useEffect(()=>{
    if(!id){
      return
    }

    getSocialMediaLinks(id).then(res=>{
      setLinkInputs({
          'Facebook':res.data?.['Facebook'] || '',
          'Instagram':res.data?.['Instagram'] || '',
          'LinkedIn':res.data?.['LinkedIn'] || '',
          'Youtube':res.data?.['Youtube'] || '',
          'Pinterest':res.data?.['Pinterest'] || '',
          'Twitter':res.data?.['Twitter'] || ''
      })
  }).catch(err =>{
      notify('Failed to get the links')
  })
    
    guides(null, id).then(res => {
      setGuideDetail(res.data)
      setIsSaved(res?.data?.bookmarked)
      setSelectedCharge(res.data?.charges?.[0]?.hour)
    }).catch(err => {
      notify(err.response && err.responsse.data.detail ? err.response.data.detail : err.message)
    })
  },[id])

  const blockHandler = () => {
    blockUser(guideDetail?.user).then(res => {
      notify(`User ${guideDetail?.userDetails?.fullName} is blocked.`)
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
  }

  const linkHandler = (link) => {
    if(link.length > 5){
        let a = document.createElement('a')
        a.target = '_blank'
        a.href = link
        a.click()
        a.remove()
    }
  }


  return (
    <div onClick={()=>handleCloseModal()}>
      {/* Image container */}
      <div className='image-container d-flex space-between' style={{backgroundImage:`url(${guideDetail?.profileImage})`}}>
        
        {/* Back button */}
        <BackButton text='' className='cursor-pointer'/>

        {/* Three vertical dots */}
        {showModal ?
            <PiX onClick={()=>setShowModal(!showModal)} className={`cursor-pointer vertical-dots custom-svg svg-block ${showModal ? 'bg-white' : ''}`}/>
          :
            <PiDotsThreeVerticalBold onClick={()=>setShowModal(!showModal)} className={`cursor-pointer vertical-dots custom-svg ${showModal ? 'svg-block' : ''}`}/>
        }
        {/* option menu and its content */}
        <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container`}>
          <ModalContentContainer Icon={LiaHandPointRightSolid } text='Nudge' onClick={()=>notificationHandler('nudged you.')}/>
          <ModalContentContainer Icon={PiBookmarkSimple} text={isSaved ? 'Un-Save Profile' : 'Save Profile'} onClick={()=>{guideBookmarkStatus(guideDetail?.id); setIsSaved(!isSaved); notify(guideDetail?.bookmarked ? 'Un-Saved Profile' : 'Saved Profile')}}/>
          <ModalContentContainer Icon={PiWarning} text='Report' onClick={()=>notificationHandler('Reported your profile.')}/>
          <ModalContentContainer Icon={AiOutlineStop } text='Block' onClick={()=>blockHandler()}/>
          <ModalContentContainer Icon={PiInfo} text='Help' onClick={()=>navigate('/FAQ')}/>
        </animated.div>

        {/* status container */}
        <div className='active-status-container d-flex items-center'>
          <PiCircleFill color={guideDetail?.isActive ? 'green' : 'lightgray'} />
          <p> {guideDetail?.isActive ? 'Active' : 'InActive'} </p>
        </div>

        {/* new text  */}
        {checkIsNew(guideDetail?.createdAt) &&
          <div className='new-text-container'>
            <h1 className='new-text'> New </h1>
          </div>
        }

      </div>

      <div className='information-container'>

        {/* name */}
        <h1 className='title-text'>{guideDetail?.userDetails?.fullName}</h1>

        {/* username */}
        <p className='username-text'>@{guideDetail?.userDetails?.username}</p>

        {/* gender and age  */}
        <div className='d-flex my-10'>
          <div className='d-flex items-center'>
            {guideDetail?.gender === 'Male' ?
              <PiGenderMale size={14}/>
              :
              <PiGenderFemale size={14}/>
            }
            <p className='male-text'>{guideDetail?.gender}</p>
          </div>
          <p className='age-text'>Age : {guideDetail?.dob && differenceInYears(new Date(), parseISO(guideDetail?.dob))}</p>
        </div>

        {/* location */}
        <div className='d-flex items-center my-10'>
          <PiMapPin size={14}/>
          <p className='location-text'>{guideDetail?.city} , {guideDetail?.state}</p>
        </div>
        
        {/* Ratings component */}
        <div className='d-flex items-center'>
          <span onClick={()=>navigate('/Rate-Review', {state: {guideName:guideDetail?.userDetails?.fullName, username:guideDetail?.userDetails?.username, guideId:guideDetail?.userDetails?.id }})}><Rating rating={4.5} /></span>
          <p style={{marginBottom:1, marginLeft:10}}>(4.5)</p>
        </div>


        {/* Chips */}
        <div className='d-flex items-center chips-container'>
          {guideDetail?.hobbiesAndInterest?.split(',')?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>

        {/* person, phone, wallet : verified  */}
        <div className='verified-container'>
          <PiUserCheck size={24}/>
          <TbPhoneCheck strokeWidth={1.4} />
          <PiWallet size={24}/>
        </div>

        {/* Bio */}
        <InformationContainer Icon={PiUser} title='Bio'>
          <p className='light-text-gd'>
            {guideDetail?.bio}
            {/* <span> Read More</span> */}
          </p>
        </InformationContainer>
 
        {/* Description */}
        <InformationContainer Icon={PiArticleThin} title='Description'>
          <p className='light-text-gd'>
            {guideDetail?.description}
            {/* <span> Read More</span> */}
          </p>
        </InformationContainer>

        <hr />
        {/* Charges */}
        <>
          {/* currency icons container and Charges text  */}
          <div className='mt-15 d-flex items-center'>
            <div className='social-media-container-gp'>
                <PiMoney />
                <PiCurrencyDollarFill />
                <PiCurrencyInrFill />
                <PiCurrencyEur />
            </div>
            <h2 className='title-text'>Charges</h2>
          </div>
          {/* Charges box container */}
          <div className='d-flex items-center space-evenly'>
            {guideDetail?.charges?.map((item, index) => {
              return(
                <ChargesContainer key={index} selected={selectedCharge} setSelected={setSelectedCharge} hoursText={item.hour} price={item.price}/>
              )
            })}
          </div>
        </>

        {/* Contact */}
        <InformationContainer Icon={PiPhone} title='Contact'>
          <ButtonComponent onClick={()=>setContactRequested(!contactRequested)} wrapperContainerStyle={{margin:'10px 0px'}} title={contactRequested ? 'Contact Requested' : 'Request for contact'} />
        </InformationContainer>
        

        <hr />
        {/* Socials */}
        <>
          {/* Social media container and Contact text  */}
          <div className='mt-15 d-flex items-center'>
            {/* social media box container */}
            <div className='social-media-container-gp'>
              <PiInstagramLogo />
              <PiWhatsappLogoFill  />
              <PiTwitterLogoFill />
              <PiLinkedinLogo />
            </div>
            <h1 className='title-text'>Socials</h1>
          </div>
          {/* Social media box container */}
          <div className='social-media-links'>
            <div onClick={()=>linkHandler(linkInputs?.Facebook)}>
              <img src={Facebook} alt='Facebook' />
              <p>@{guideDetail?.userDetails?.username}</p>
            </div>
            <div onClick={()=>linkHandler(linkInputs?.Instagram)}>
              <img src={Instagram} alt='Instagram' />
              <p>@{guideDetail?.userDetails?.username}</p>
            </div>
            <div onClick={()=>linkHandler(linkInputs?.Youtube)}>
              <img src={Youtube} alt='Youtube' />
              <p>@{guideDetail?.userDetails?.username}</p>
            </div>
            <div onClick={()=>linkHandler(linkInputs?.LinkedIn)}>
              <img src={Linkedin} alt='Linkedin' />
              <p>@{guideDetail?.userDetails?.username}</p>
            </div>
          </div>
        </>

        {/* Profession */}
        <InformationContainer Icon={PiSuitcaseFill} title='Profession'>
          <div className='profession-container d-flex items-center'>
            <p>Designer</p>
            <PiArrowRight />
            <p>Interior Designer</p>
          </div>
        </InformationContainer>

        {/* Languages */}
        <InformationContainer Icon={IoEarthOutline } title='Language'>
          <div className='d-flex flex-wrap mb-10'>
            {guideDetail?.languages?.map((item, index)=> (
              <LanguageBoxRenderer key={index} language={item.language} efficiency={item.fluency}/>
            ))}
            {/* TODO: what about the efficiency ? */}
          </div>
        </InformationContainer>

        {/* Hobbies */}
        <InformationContainer Icon={PiGameController} Icon1={PiFootball} title='Hobbies'>
          <div className='d-flex items-center chips-container'>
            {guideDetail?.hobbiesAndInterest?.split(',')?.map((item, index)=> (
              <p key={index}>{item}</p>
            ))}
          </div>
        </InformationContainer>


        {/* Skills */}
        <InformationContainer Icon={PiLightbulbFilament} title='Skills'>
          <div className='d-flex items-center chips-container'>
            {guideDetail?.skills?.split(',')?.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </InformationContainer>

        {/* Itinerary */}
        {guideDetail?.itinerary?.length > 0 &&
        <>
          <hr />
          <div className='my-15 Itinerary-container'>
            {/* Text container */}
            <div className='d-flex items-center space-between'>
              <div className='d-flex items-center'>
                <PiBook />
                <h1 className='px-10 title-text'>Itinerary</h1>
              </div>
              <h1 className='subtitle-text'>{guideDetail?.itinerary?.length} days</h1>
            </div>
            <Accordion items={guideDetail?.itinerary}/>
          </div>
        </>
        }

        {/* Buttons */}
        <ButtonComponent title='Request for personaized tour' wrapperContainerStyle={{padding:10}} onClick={()=>navigate('/Personalized-Tour', {state:{
          image:guideDetail?.profileImage,
          fullName:guideDetail?.userDetails?.fullName,
          username:guideDetail?.userDetails?.username,
          id:guideDetail?.userDetails?.id,
          city:guideDetail?.cityOfService
        }})}/>
        <ButtonComponent onClick={()=>navigate('/Chat', {state:{userId:guideDetail?.userDetails?.id, image:guideDetail?.profileImage, fullName:guideDetail?.userDetails?.fullName, username:guideDetail?.userDetails?.username}})}  Icon={PiPaperPlaneTiltFill } iconColor='var(--primary)' title='Message' wrapperContainerStyle={{padding:10, margin:"10px 0px", backgroundImage:"linear-gradient(to right, white, whitesmoke)"}} buttonTextColor='var(--primary)'/>
        <ButtonComponent 
          title='Book Now' 
          wrapperContainerStyle={{padding:15, marginBottom:10, backgroundImage:`url('${design}')`, backgroundColor:"var(--primary)"}} 
          
          onClick={()=>setShowDrawer(!showDrawer)}
        />
        <BottomDrawer 
          state={showDrawer}
          content={
              <BasicBookingForm
                guideUsername={guideDetail?.userDetails?.username}
                image={guideDetail?.profileImage}
                fullName={guideDetail?.userDetails?.fullName}
                onClick={()=>setShowDrawer(!showDrawer)}
                user1_id={userInfo?.id}  
                user1_username={userInfo?.username}
                guideId={guideDetail?.userDetails?.id}
              />}
          yValue={55}
        />
      </div>
      
    </div>
  )
}

export default GuideDetailsPage