import React, { useContext, useEffect, useState } from 'react'
import { Login } from '../../assets/images'
import './loginpage.styles.scss'
import { ButtonComponent, DividerComponent, IconInput } from '../../components'
import { IoLockClosedOutline, IoMailOutline  } from 'react-icons/io5'
import { Google } from '../../assets/icons'
import { useNavigate } from 'react-router-dom'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { login } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { notify } from '../../components/Toast'

const LoginPage = () => {
  const [username, setUsername] = useState('faijan')
  const [password, setPassword] = useState('1234')
  const [showPassword, setShowPassword] = useState(false)
  const { isTourist, userInfo, setUserInfo } = useContext(StateContext)

  const navigate = useNavigate()
  const loginHandler = () => {
    login(username, password).then((res) => {
      localStorage.setItem("auth-cg", JSON.stringify(res.data));
      setUserInfo(res.data)
      navigate('/Home')
    }).catch(error => {
        let message = error.response && error.response.data.detail ? error.response.data.detail : error.message
        notify(message, 'crimson')
    })
  }

  useEffect(()=>{
    if(userInfo){
      navigate('/Home')
    }
    // eslint-disable-next-line
  },[])

  return (
    <div className='container'>
      <img src={Login} alt='India Gate with bird and clouds' />
      <div className='text-container'>
        <h1>Login as {isTourist ? 'Tourist' : 'Guide'}</h1>
        <p>Start exploring fascination places around the world.</p>
      </div>
      <div className='inputs-container'>
        <IconInput value={username} onChange={(e)=>setUsername(e.target.value)} Icon={ IoMailOutline } placeholder='Email'/>
        <IconInput value={password} onChange={(e)=>setPassword(e.target.value)} Icon={ IoLockClosedOutline } type={`${showPassword ? 'text' : 'password'}`} placeholder='Password' EndIcon={ showPassword ? BsEye : BsEyeSlash } endIconFunction={()=>setShowPassword(!showPassword)}/>
      </div>
      <p onClick={()=>navigate('/Forgot-Password')} className='forgot-password'>Forgot Password ?</p>
      <ButtonComponent title='Login' onClick={()=>loginHandler()} wrapperContainerStyle={{margin:'0px 20px', justifyContent:"center"}} />
      <DividerComponent text='OR'/>
      <ButtonComponent title='Sign Up with Google' img={Google} buttonTextColor='black'  wrapperContainerStyle={{margin:'0px 20px', backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)"}}/>
      <p className='account-text'>Don't have an account ? <span onClick={()=>navigate('/Signup')}>Create Account.</span></p>
    </div>
  )
}

export default LoginPage

