export const GUIDE_SERVICES_OPTIONS = [
    {id:1, value:'None', text:"Are you willing to do guide services ?"},
    {id:2, value:"Yes", text:"Yes"},
    {id:3, value:"No", text:"No"},
  ]
  
export const COUNTRY = [
    {id: 1, value: 'Afghanistan', text: "Afghanistan"},
    {id: 2, value: 'Albania', text: "Albania"},
    {id: 3, value: 'Algeria', text: "Algeria"},
    {id: 4, value: 'Andorra', text: "Andorra"},
    {id: 5, value: 'Angola', text: "Angola"},
    {id: 6, value: 'Antigua and Barbuda', text: "Antigua and Barbuda"},
    {id: 7, value: 'Argentina', text: "Argentina"},
    {id: 8, value: 'Armenia', text: "Armenia"},
    {id: 9, value: 'Australia', text: "Australia"},
    {id: 10, value: 'Austria', text: "Austria"},
    {id: 11, value: 'Azerbaijan', text: "Azerbaijan"},
    {id: 12, value: 'Bahamas', text: "Bahamas"},
    {id: 13, value: 'Bahrain', text: "Bahrain"},
    {id: 14, value: 'Bangladesh', text: "Bangladesh"},
    {id: 15, value: 'Barbados', text: "Barbados"},
    {id: 16, value: 'Belarus', text: "Belarus"},
    {id: 17, value: 'Belgium', text: "Belgium"},
    {id: 18, value: 'Belize', text: "Belize"},
    {id: 19, value: 'Benin', text: "Benin"},
    {id: 20, value: 'Bhutan', text: "Bhutan"},
    {id: 21, value: 'Bolivia', text: "Bolivia"},
    {id: 22, value: 'Bosnia and Herzegovina', text: "Bosnia and Herzegovina"},
    {id: 23, value: 'Botswana', text: "Botswana"},
    {id: 24, value: 'Brazil', text: "Brazil"},
    {id: 25, value: 'Brunei', text: "Brunei"},
    {id: 26, value: 'Bulgaria', text: "Bulgaria"},
    {id: 27, value: 'Burkina Faso', text: "Burkina Faso"},
    {id: 28, value: 'Burundi', text: "Burundi"},
    {id: 29, value: 'Cabo Verde', text: "Cabo Verde"},
    {id: 30, value: 'Cambodia', text: "Cambodia"},
    {id: 31, value: 'Cameroon', text: "Cameroon"},
    {id: 32, value: 'Canada', text: "Canada"},
    {id: 33, value: 'Central African Republic', text: "Central African Republic"},
    {id: 34, value: 'Chad', text: "Chad"},
    {id: 35, value: 'Chile', text: "Chile"},
    {id: 36, value: 'China', text: "China"},
    {id: 37, value: 'Colombia', text: "Colombia"},
    {id: 38, value: 'Comoros', text: "Comoros"},
    {id: 39, value: 'Congo, Democratic Republic of the', text: "Congo, Democratic Republic of the"},
    {id: 40, value: 'Congo, Republic of the', text: "Congo, Republic of the"},
    {id: 41, value: 'Costa Rica', text: "Costa Rica"},
    {id: 42, value: 'Croatia', text: "Croatia"},
    {id: 43, value: 'Cuba', text: "Cuba"},
    {id: 44, value: 'Cyprus', text: "Cyprus"},
    {id: 45, value: 'Czech Republic', text: "Czech Republic"},
    {id: 46, value: 'Denmark', text: "Denmark"},
    {id: 47, value: 'Djibouti', text: "Djibouti"},
    {id: 48, value: 'Dominica', text: "Dominica"},
    {id: 49, value: 'Dominican Republic', text: "Dominican Republic"},
    {id: 50, value: 'Ecuador', text: "Ecuador"},
    {id: 51, value: 'Egypt', text: "Egypt"},
    {id: 52, value: 'El Salvador', text: "El Salvador"},
    {id: 53, value: 'Equatorial Guinea', text: "Equatorial Guinea"},
    {id: 54, value: 'Eritrea', text: "Eritrea"},
    {id: 55, value: 'Estonia', text: "Estonia"},
    {id: 56, value: 'Eswatini', text: "Eswatini"},
    {id: 57, value: 'Ethiopia', text: "Ethiopia"},
    {id: 58, value: 'Fiji', text: "Fiji"},
    {id: 59, value: 'Finland', text: "Finland"},
    {id: 60, value: 'France', text: "France"},
    {id: 61, value: 'Gabon', text: "Gabon"},
    {id: 62, value: 'Gambia', text: "Gambia"},
    {id: 63, value: 'Georgia', text: "Georgia"},
    {id: 64, value: 'Germany', text: "Germany"},
    {id: 65, value: 'Ghana', text: "Ghana"},
    {id: 66, value: 'Greece', text: "Greece"},
    {id: 67, value: 'Grenada', text: "Grenada"},
    {id: 68, value: 'Guatemala', text: "Guatemala"},
    {id: 69, value: 'Guinea', text: "Guinea"},
    {id: 70, value: 'Guinea-Bissau', text: "Guinea-Bissau"},
    {id: 71, value: 'Guyana', text: "Guyana"},
    {id: 72, value: 'Haiti', text: "Haiti"},
    {id: 73, value: 'Honduras', text: "Honduras"},
    {id: 74, value: 'Hungary', text: "Hungary"},
    {id: 75, value: 'Iceland', text: "Iceland"},
    {id: 76, value: 'India', text: "India"},
    {id: 77, value: 'Indonesia', text: "Indonesia"},
    {id: 78, value: 'Iran', text: "Iran"},
    {id: 79, value: 'Iraq', text: "Iraq"},
    {id: 80, value: 'Ireland', text: "Ireland"},
    {id: 81, value: 'Israel', text: "Israel"},
    {id: 82, value: 'Italy', text: "Italy"},
    {id: 83, value: 'Jamaica', text: "Jamaica"},
    {id: 84, value: 'Japan', text: "Japan"},
    {id: 85, value: 'Jordan', text: "Jordan"},
    {id: 86, value: 'Kazakhstan', text: "Kazakhstan"},
    {id: 87, value: 'Kenya', text: "Kenya"},
    {id: 88, value: 'Kiribati', text: "Kiribati"},
    {id: 89, value: 'Kuwait', text: "Kuwait"},
    {id: 90, value: 'Kyrgyzstan', text: "Kyrgyzstan"},
    {id: 91, value: 'Laos', text: "Laos"},
    {id: 92, value: 'Latvia', text: "Latvia"},
    {id: 93, value: 'Lebanon', text: "Lebanon"},
    {id: 94, value: 'Lesotho', text: "Lesotho"},
    {id: 95, value: 'Liberia', text: "Liberia"},
    {id: 96, value: 'Libya', text: "Libya"},
    {id: 97, value: 'Liechtenstein', text: "Liechtenstein"},
    {id: 98, value: 'Lithuania', text: "Lithuania"},
    {id: 99, value: 'Luxembourg', text: "Luxembourg"},
    {id: 100, value: 'Madagascar', text: "Madagascar"},
    {id: 101, value: 'Malawi', text: "Malawi"},
    {id: 102, value: 'Malaysia', text: "Malaysia"},
    {id: 103, value: 'Maldives', text: "Maldives"},
    {id: 104, value: 'Mali', text: "Mali"},
    {id: 105, value: 'Malta', text: "Malta"},
    {id: 106, value: 'Marshall Islands', text: "Marshall Islands"},
    {id: 107, value: 'Mauritania', text: "Mauritania"},
    {id: 108, value: 'Mauritius', text: "Mauritius"},
    {id: 109, value: 'Mexico', text: "Mexico"},
    {id: 110, value: 'Micronesia', text: "Micronesia"},
    {id: 111, value: 'Moldova', text: "Moldova"},
    {id: 112, value: 'Monaco', text: "Monaco"},
    {id: 113, value: 'Mongolia', text: "Mongolia"},
    {id: 114, value: 'Montenegro', text: "Montenegro"},
    {id: 115, value: 'Morocco', text: "Morocco"},
    {id: 116, value: 'Mozambique', text: "Mozambique"},
    {id: 117, value: 'Myanmar', text: "Myanmar"},
    {id: 118, value: 'Namibia', text: "Namibia"},
    {id: 119, value: 'Nauru', text: "Nauru"},
    {id: 120, value: 'Nepal', text: "Nepal"},
    {id: 121, value: 'Netherlands', text: "Netherlands"},
    {id: 122, value: 'New Zealand', text: "New Zealand"},
    {id: 123, value: 'Nicaragua', text: "Nicaragua"},
    {id: 124, value: 'Niger', text: "Niger"},
    {id: 125, value: 'Nigeria', text: "Nigeria"},
    {id: 126, value: 'North Korea', text: "North Korea"},
    {id: 127, value: 'North Macedonia', text: "North Macedonia"},
    {id: 128, value: 'Norway', text: "Norway"},
    {id: 129, value: 'Oman', text: "Oman"},
    {id: 130, value: 'Pakistan', text: "Pakistan"},
    {id: 131, value: 'Palau', text: "Palau"},
    {id: 132, value: 'Panama', text: "Panama"},
    {id: 133, value: 'Papua New Guinea', text: "Papua New Guinea"},
    {id: 134, value: 'Paraguay', text: "Paraguay"},
    {id: 135, value: 'Peru', text: "Peru"},
    {id: 136, value: 'Philippines', text: "Philippines"},
    {id: 137, value: 'Poland', text: "Poland"},
    {id: 138, value: 'Portugal', text: "Portugal"},
    {id: 139, value: 'Qatar', text: "Qatar"},
    {id: 140, value: 'Romania', text: "Romania"},
    {id: 141, value: 'Russia', text: "Russia"},
    {id: 142, value: 'Rwanda', text: "Rwanda"},
    {id: 143, value: 'Saint Kitts and Nevis', text: "Saint Kitts and Nevis"},
    {id: 144, value: 'Saint Lucia', text: "Saint Lucia"},
    {id: 145, value: 'Saint Vincent and the Grenadines', text: "Saint Vincent and the Grenadines"},
    {id: 146, value: 'Samoa', text: "Samoa"},
    {id: 147, value: 'San Marino', text: "San Marino"},
    {id: 148, value: 'Sao Tome and Principe', text: "Sao Tome and Principe"},
    {id: 149, value: 'Saudi Arabia', text: "Saudi Arabia"},
    {id: 150, value: 'Senegal', text: "Senegal"},
    {id: 151, value: 'Serbia', text: "Serbia"},
    {id: 152, value: 'Seychelles', text: "Seychelles"},
    {id: 153, value: 'Sierra Leone', text: "Sierra Leone"},
    {id: 154, value: 'Singapore', text: "Singapore"},
    {id: 155, value: 'Slovakia', text: "Slovakia"},
    {id: 156, value: 'Slovenia', text: "Slovenia"},
    {id: 157, value: 'Solomon Islands', text: "Solomon Islands"},
    {id: 158, value: 'Somalia', text: "Somalia"},
    {id: 159, value: 'South Africa', text: "South Africa"},
    {id: 160, value: 'South Korea', text: "South Korea"},
    {id: 161, value: 'South Sudan', text: "South Sudan"},
    {id: 162, value: 'Spain', text: "Spain"},
    {id: 163, value: 'Sri Lanka', text: "Sri Lanka"},
    {id: 164, value: 'Sudan', text: "Sudan"},
    {id: 165, value: 'Suriname', text: "Suriname"},
    {id: 166, value: 'Sweden', text: "Sweden"},
    {id: 167, value: 'Switzerland', text: "Switzerland"},
    {id: 168, value: 'Syria', text: "Syria"},
    {id: 169, value: 'Taiwan', text: "Taiwan"},
    {id: 170, value: 'Tajikistan', text: "Tajikistan"},
    {id: 171, value: 'Tanzania', text: "Tanzania"},
    {id: 172, value: 'Thailand', text: "Thailand"},
    {id: 173, value: 'Timor-Leste', text: "Timor-Leste"},
    {id: 174, value: 'Togo', text: "Togo"},
    {id: 175, value: 'Tonga', text: "Tonga"},
    {id: 176, value: 'Trinidad and Tobago', text: "Trinidad and Tobago"},
    {id: 177, value: 'Tunisia', text: "Tunisia"},
    {id: 178, value: 'Turkey', text: "Turkey"},
    {id: 179, value: 'Turkmenistan', text: "Turkmenistan"},
    {id: 180, value: 'Tuvalu', text: "Tuvalu"},
    {id: 181, value: 'Uganda', text: "Uganda"},
    {id: 182, value: 'Ukraine', text: "Ukraine"},
    {id: 183, value: 'United Arab Emirates', text: "United Arab Emirates"},
    {id: 184, value: 'United Kingdom', text: "United Kingdom"},
    {id: 185, value: 'United States', text: "United States"},
    {id: 186, value: 'Uruguay', text: "Uruguay"},
    {id: 187, value: 'Uzbekistan', text: "Uzbekistan"},
    {id: 188, value: 'Vanuatu', text: "Vanuatu"},
    {id: 189, value: 'Vatican City', text: "Vatican City"},
    {id: 190, value: 'Venezuela', text: "Venezuela"},
    {id: 191, value: 'Vietnam', text: "Vietnam"},
    {id: 192, value: 'Yemen', text: "Yemen"},
    {id: 193, value: 'Zambia', text: "Zambia"},
    {id: 194, value: 'Zimbabwe', text: "Zimbabwe"}
  
]