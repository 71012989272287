import React, { useState } from 'react'
import { PiCurrencyInr, PiX } from 'react-icons/pi'
import { ButtonComponent, ChipComponent } from '../../components'
import './filtercontent.styles.scss'
import 'react-range-slider-input/dist/style.css';
import RangeSlider from 'react-range-slider-input';

const TIME_CHIPS = ['3Hrs', '6Hrs', '12Hrs']
const LANG_CHIPS = ['Hindi','Marathi','Telugu','English','Tamil','Kannada','Malayalam']
const AVAILABILITY_CHIPS = ['Active','Instantly Confirm', 'Virtual Tour']
const GENDER_PREFERENCE_CHIPS = ['Male','Female', 'Other']
const EXPERTISE_CHIPS = ['History', 'Guide', 'Guides', 'more+']

const TitleAndChips = ({title, setState, CHIPS=[], state=[]}) => {
    const clickHandler = (item) => {
        if (state.includes(item)){
            const results = state.filter(title => title !== item)
            setState(results)
            return
        }
        setState([...state, item])
    }
    return(
        <div className='title-chips-container'>
            <h4 className='subtitle-text'>{title}</h4>
            <div className='d-flex space-evenly flex-wrap'>
                {CHIPS.map((item, index) => {
                    return (
                        <ChipComponent onClick={()=>clickHandler(item)} style={{transition:"all 0.2s ease-in-out", border:'0.5px solid var(--primary)', color:`${state.includes(item) ? 'white'  : 'black' }`, backgroundColor:`${state.includes(item) ? 'orange' : "white"}`, padding:`10px ${item.length > 10 ? '15px'  : '30px'}`}} text={item} key={`${item}-${index}`}/>
                    )
                })}
            </div>
            <hr />
        </div>
    )
}

const FilterContent = ({onClick}) => {

    const [selectedTimeChips, setSelectedTimeChips] = useState([])
    const [selectedLangChips, setSelectedLangChips] = useState([])
    const [selectedAvailableChips, setSelectedAvailableChips] = useState([])
    const [selectedExpertiseChips, setSelectedExpertiseChips] = useState([])
    const [selectedGenderChips, setSelectedGenderChips] = useState([])
    const [selectedInterestChips, setSelectedInterestChips] = useState([])
    const [selectedProfessionChips, setSelectedProfessionChips] = useState([])

    const resetHandler = () => {
        setSelectedTimeChips([])
        setSelectedLangChips([])
        setSelectedAvailableChips([])
        setSelectedExpertiseChips([])
        setSelectedGenderChips([])
        setSelectedInterestChips([])
        setSelectedProfessionChips([])
    }

  return (
    <div className='filter-container'>
        {/* Filter : text  and close icon*/}
        <div className='my-20 d-flex space-between items-center'>
            <h1 className='title-text'>Filter</h1>
            <PiX onClick={onClick} className='close-icon'/>
        </div>

        {/* Price Range text & range */}
        <div className='range-container d-flex items-center space-between'>
            <h4 className='price-range-text'>Price Range</h4>
            <div className='d-flex items-center'>
                <PiCurrencyInr />
                <input />
                <span>-</span>
                <input />
            </div>
        </div>

        {/* Filter Range : check library */}
        <RangeSlider className='range' min={10} max={100}/>
        <br/>
        <hr />
        

        <TitleAndChips title='Time' CHIPS={TIME_CHIPS} state={selectedTimeChips} setState={setSelectedTimeChips} />
        <TitleAndChips title='Language' CHIPS={LANG_CHIPS} state={selectedLangChips} setState={setSelectedLangChips}/>
        <TitleAndChips title='Availability' CHIPS={AVAILABILITY_CHIPS} state={selectedAvailableChips} setState={setSelectedAvailableChips}/>
        <TitleAndChips title='Gender' CHIPS={GENDER_PREFERENCE_CHIPS} state={selectedGenderChips} setState={setSelectedGenderChips}/>
        <TitleAndChips title='Area Of Expertise' CHIPS={EXPERTISE_CHIPS} state={selectedExpertiseChips} setState={setSelectedExpertiseChips}/>
        <TitleAndChips title='Interest' CHIPS={EXPERTISE_CHIPS} state={selectedInterestChips} setState={setSelectedInterestChips}/>
        <TitleAndChips title='Profession' CHIPS={EXPERTISE_CHIPS} state={selectedProfessionChips} setState={setSelectedProfessionChips}/>
        <div className='d-flex items-center space-between'>
            <ButtonComponent onClick={()=>resetHandler()} title='Reset' buttonTextColor='black' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)", width:"45%", marginRight:10}}/>
            <ButtonComponent title='Apply' wrapperContainerStyle={{width:"45%"}}/>
        </div>
    </div>
  )
}

export default FilterContent