import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (message, color='black', position='top-left') =>  
  toast(`${message}`, {
  position,
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {textAlign:"center", color:color, margin:"0px 5px"}
});

function Toast(){

  return (
    <ToastContainer />
  );
}

export default Toast;