import React, { useContext, useEffect, useRef, useState } from 'react'
import './editprofilepage.styles.scss'
import { BackButton, ButtonComponent, IconInput, LineText} from '../../components'
import { notify } from '../../components/Toast'
import { getHourCharges, getItineraryImages, getUserDetails, updateChargesDetails, updateItineraryData, updateUserDetails } from '../../utils/service'
import { StateContext } from '../../context/stateContext'
import { PiBeachBallThin, PiFlag, PiGraduationCapThin, PiPen, PiPencil, PiPencilSimpleLineLight, PiTranslate } from 'react-icons/pi'
import { CustomDropDown } from '../RegisterationWizardPage/PersonalInformation'
import { url } from '../../config'
import { useNavigate } from 'react-router-dom'
import CountryFlag from 'react-country-flag';
import countryLists from 'react-select-country-list'

const UploadImage = ({src, onChange}) => {
    const inputRef = useRef()
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        // Check if src is a valid File object before creating a preview
        if (src && typeof src === 'object' && src instanceof Blob) {
            setPreview(URL.createObjectURL(src));
        }
        if(src && typeof src === 'string'){
            setPreview(url + src)
        }
    }, [src]);

    return (
        <div onClick={()=>inputRef?.current?.click()} className='d-flex column content-center border-rounded items-center p-5 mx-5 bg-lightgray'>
            {src ?
                <img style={{height:40, width:40, borderRadius:4}} src={preview} alt='' />
                :
                <p className='px-10 fs-30 text-lightgray'>+</p>
            }
            <input onChange={(e)=>onChange(e.target.files[0])} ref={inputRef} hidden type='file'/>
        </div>
    )
}

const EditProfilePage = () => {
    let index = 0
    let itineraryIndex = 0
    let hourlyIndex = 0
    const options = countryLists().getData()
    const navigate = useNavigate()
    const [userDetails, setUserDetails] = useState({})
    const {userInfo} = useContext(StateContext)
    const [form, setForm] = useState({
        'name':'',
        'about':'',
        'dob':'',
        'gender':'',
        'country':'',
        'countryCode':'',
        'showCountryFlag':true,
        'state':'',
        'city':'',
        'zipCode':'',
        'profession':'',
        'isStudent':'',
        'justifyInFewWords':'',
        'hobbiesAndInterest':'',
        'languages':{
            0:{language:"", fluency:"'"}
        },
        "profileImage":"",
        "image1":null,
        "image2":null,
        "image3":null,
        "image4":null,
    })

    const [hoursBilling, setHoursBilling] = useState({
        0:{hour:'1h', price:'0'},
    })

    const [itineraryData, setItineraryData] = useState({
        0:{title:"", description:"", image:null}
    })


    const changeHandler = (key, text) => {
        setForm({...form, [key]:text})
    }

    const countryChangeHandler = (code) => {
        if (code === 'none') {
            setForm(prevForm => ({
                ...prevForm,
                countryCode: '',
                country: ''
            }));
            return;
        }
        changeHandler('countryCode', code); 
        const countryName = options.find(country => country.value === code)?.label || '';
        setForm(prevForm => ({
            ...prevForm,
            countryCode: code,
            country: countryName
        }));
    }
    
    const addLanguageHandler = () => {
        setForm({...form, languages: {...form?.languages,  [index+1] : {'language':'', 'fluency':''}}})
    }

    
    const languageInputHandler = (index, text) =>{
        setForm({...form, languages: {...form?.languages,  [index] : {...form?.languages?.[index], 'language':text}}})
    }

    const addItineraryHandler = () => {
        setItineraryData({...itineraryData, [itineraryIndex+1]: {title:"", description:""}})
    }

    const ItineraryInputHandler = (index, key, text, id) =>{
        setItineraryData({...itineraryData, [index] : {...itineraryData[index], [key]:text}})
        if(id){
            itineraryData[index]['_id'] = id
        }
    }
    
    const addHourlyBillingsHandler = () => {
        setHoursBilling({...hoursBilling, [hourlyIndex+1]: {hour:"", price:""}})
    }


    const HourlyBillingsInputHandler = (index, key, text, id) =>{
        setHoursBilling({...hoursBilling, [index] : {...hoursBilling[index], [key]:text}})
        if(id){
            hoursBilling[index]['_id'] = id
        }
    }

    const selectHandler = (index, value) => {
        setForm({...form, languages: {...form?.languages,  [index] : {...form?.languages?.[index], 'fluency':value}}})
    }

    const updateUserDetailsHandler = () => {
        updateUserDetails(form).then(res => {
            notify('Details updated')
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    const updateItineraryDataHandler = () => {
        updateItineraryData(itineraryData).then(res => {
            notify('Itinerary data updated')
        }).catch(err => {
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    const updateHourlyPrice = () => {
        updateChargesDetails(hoursBilling).then(res =>{
            notify('Hourly data updated')
        }).catch(err =>{
            notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }

    useEffect(()=>{
        if(userInfo?.id){
            getUserDetails(userInfo.id).then(res =>{
                let data = res.data;
                setUserDetails(res.data)
                setForm({
                    'name':data.userDetails.fullName,
                    'about':data.about,
                    'dob':data.dob,
                    'gender':data.gender,
                    'country':data.country,
                    'countryCode':data.countryCode,
                    'showCountryFlag':data.showCountryFlag,
                    'state':data.state,
                    'city':data.city,
                    'zipCode':data.zipCode,
                    'profession':data.profession,
                    'isStudent':data.isStudent,
                    'justifyInFewWords':data.justifyInFewWords,
                    'hobbiesAndInterest':data.hobbiesAndInterest,
                    'languages':{ ...data.languages },
                    'profileImage':data.profileImage,
                    'image1':data.image1,
                    'image2':data.image2,
                    'image3':data.image3,
                    'image4':data.image4,

                })
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

            getItineraryImages().then(res => {
                const data = {}
                res.data?.forEach((item, index)=>{
                    data[index] = {_id : item.id, title:item.title, description:item.description, image:item.image}
                })
                if(Object.keys(data).length > 0){
                    setItineraryData(data)
                }
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

            getHourCharges().then(res => {
                const data = {}
                res.data?.forEach((item, index)=>{
                    data[index] = {hour:item.hour, price:String(item.price), _id:item.id}
                })
                if(Object.keys(data).length > 0){
                    setHoursBilling(data)
                }
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })

        }
    },[userInfo])
    
    return (
        <div className='px-20'>
            <BackButton style={{marginLeft:-20}}/>
            <div className='px-10'>
                {/* Personal information : text */}
                <h2 className='fw-500 text-center'>Manage Profile</h2>

                {/* Profile image , flag, and plus icon */}
                <div className='upload-image text-center'>
                    {form.showCountryFlag &&
                        <CountryFlag className='country-flag' countryCode={form.countryCode} svg style={{ width: '20px', height: '20px', borderRadius:"0% !important" }} />
                    }
                    <img className='profile-image w-25 h-25' onClick={()=>document.getElementById('input-id').click()} src={userDetails?.profileImage} alt={userDetails?.username}/>
                    <input onChange={(file)=>setForm({...form, profileImage:file})} type='file' hidden id='input-id' />
                    <div onClick={()=>document.getElementById('input-id').click()}>
                        <p>+</p>
                    </div>
                </div>

                {/* other 4 images */}
                <div className='d-flex content-center'>
                    <UploadImage src={form.image1} onChange={(file)=>setForm({...form, image1:file})}/>
                    <UploadImage src={form.image2} onChange={(file)=>setForm({...form, image2:file})}/>
                    <UploadImage src={form.image3} onChange={(file)=>setForm({...form, image3:file})}/>
                    <UploadImage src={form.image4} onChange={(file)=>setForm({...form, image4:file})}/>
                </div>
            </div>
            {/* Personal Information seperator */}
            <LineText text='Personal Information' />
            {/* name, about, dob, gender, country,  */}
            <div>
                <IconInput value={form?.name} onChange={(e)=>changeHandler('name', e.target.value)} placeholder='Name'/>
                <IconInput value={form?.about} onChange={(e)=>changeHandler('about', e.target.value)} placeholder='About'/>
                <div className='half-input-container'>
                    <IconInput value={form?.dob} hideLabel={true} type='date' onChange={(e)=>changeHandler('dob', e.target.value)} placeholder='Date Of Birth'/>
                    <IconInput value={form?.gender} onChange={(e)=>changeHandler('gender', e.target.value)} placeholder='Gender'/>
                    <select value={form?.countryCode} onChange={(e)=>countryChangeHandler(e.target.value)} className='w-50 ep-customize-select'>
                        <option value='none'>Select Country</option>
                        {options.map(item => {
                            return (
                            <option key={item.label} value={item.value}>{item.label}</option>
                        )})}
                    </select>
                    <IconInput value={form?.state} onChange={(e)=>changeHandler('state', e.target.value)} placeholder='State'/>
                    <IconInput value={form?.city} onChange={(e)=>changeHandler('city', e.target.value)} placeholder='City'/>
                    {/* <IconInput value={form?.zipCode} type='number' onChange={(e)=>changeHandler('zipCode', e.target.value)} placeholder='Zip Code'/> */}
                </div>
                <div className='d-flex flex-end items-center'>
                    <PiFlag size={22} style={{margin:'0px 5px'}}/>
                    <p>Show Flag Next to Profile</p>
                    <input checked={form?.showCountryFlag} onChange={()=>changeHandler('showCountryFlag', !form?.showCountryFlag)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
                </div>
                <LineText text='Profession' />
                <IconInput value={form?.profession} onChange={(e)=>changeHandler('profession', e.target.value)} placeholder='Profession'/>
             
                <div className='d-flex flex-end items-center'>
                    <PiGraduationCapThin size={22} style={{margin:'0px 5px'}}/>
                    <p>I Am a Student</p>
                    <input checked={form?.isStudent} onChange={()=>changeHandler('isStudent', !form?.isStudent)} type='checkbox' style={{marginLeft:'10px', width:20, height:20}}/> 
                </div>
                <IconInput value={form?.justifyInFewWords} onChange={(e)=>changeHandler('justifyInFewWords', e.target.value)} Icon={PiPencilSimpleLineLight} iconSize={30} placeholder='Justify (optional)' />
                <LineText text='Lnaguages, Hobbies & Interest' />
                {Object.values(form?.languages)?.map((item, i) => {
                    index = Object.keys(form?.languages).length - 1
                    return(
                    <div className='cutomize-input-box' key={i}>
                        <IconInput style={{width:'90%'}} value={item?.language} onChange={(e)=>languageInputHandler(i, e.target.value)} Icon={PiTranslate} iconSize={30} placeholder='Type a lanugage' />
                        <CustomDropDown index={i} state={item.fluency} setState={selectHandler} />
                    </div>
                    )
                })}
                <div className='d-flex flex-end items-center'>
                    <p onClick={()=>addLanguageHandler()} className='cursor-pointer text-whitesmoke bg-primary px-5 border-rounded mx-3 fs-14'>+</p>
                    <p onClick={()=>addLanguageHandler()} className='cursor-pointer fs-14'>Add more languages</p>
                </div>
                <IconInput value={form?.hobbiesAndInterest} onChange={(e)=>changeHandler('hobbiesAndInterest', e.target.value)} Icon={PiBeachBallThin} iconSize={30} placeholder='Hobbies & Interest' />
                <br />
                <ButtonComponent onClick={()=>updateUserDetailsHandler()} title='Update Details'/>
                <ButtonComponent title='Cancel' buttonTextColor='black' onClick={()=>navigate(-1)} wrapperContainerStyle={{margin:"10px 0px", backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)"}}/>
                

                {userInfo?.isGuide &&
                    <>
                        <LineText text='Itinerary' />
                        {Object.keys(itineraryData).map((item, index)=>{
                            itineraryIndex = Object.keys(itineraryData).length - 1
                            return(
                                <div style={{marginBottom:10}} key={item}>
                                    <p>Day {index + 1}</p>
                                    <IconInput value={itineraryData[index]?.title} onChange={(e)=>ItineraryInputHandler(index, 'title', e.target.value, itineraryData[index]?.id)}  Icon={PiPen} placeholder='Title e.g. Taj Hotel'/>
                                    <IconInput value={itineraryData[index]?.description} onChange={(e)=>ItineraryInputHandler(index, 'description', e.target.value, itineraryData[index]?.id)}  Icon={PiPencil} placeholder='Description about the place'/>
                                    <div style={{height:60, width:60, marginLeft:-10}}>
                                        <UploadImage src={itineraryData[index]?.image} onChange={(file)=>setItineraryData({...itineraryData, [index]:{...itineraryData[index], image:file}})}/>
                                    </div>
                                </div>
                            )
                        })}
                        <p className='text-primary cursor-pointer fs-14 my-1' onClick={itineraryIndex < 3 ? ()=>addItineraryHandler() : ()=>alert('limit reached.')}>Add More</p>
                        <ButtonComponent onClick={()=>updateItineraryDataHandler()} title='Update Itinerary'/>
                    </>
                }

                {userInfo?.isGuide &&
                    <>
                        <LineText text='Hour Charges' />
                        {Object.keys(hoursBilling).map((item, index)=>{
                            hourlyIndex = Object.keys(hoursBilling).length - 1
                            return(
                                <div style={{marginBottom:10}} key={item}>
                                    <p>Day {index + 1}</p>
                                    <IconInput value={hoursBilling[index]?.hour} onChange={(e)=>HourlyBillingsInputHandler(index, 'hour', e.target.value, hoursBilling[index]?.id)}  Icon={PiPen} placeholder='Hour e.g. 1h'/>
                                    <IconInput value={hoursBilling[index]?.price} onChange={(e)=>HourlyBillingsInputHandler(index, 'price', e.target.value, hoursBilling[index]?.id)}  Icon={PiPencil} placeholder='Price e.g. 499'/>
                                </div>
                            )
                        })}
                        <p className='text-primary cursor-pointer fs-14 my-1' onClick={hourlyIndex < 2 ? ()=>addHourlyBillingsHandler() : ()=>alert('limit reached.')}>Add More</p>
                        <ButtonComponent onClick={()=>updateHourlyPrice()} title='Update Prices'/>
                    </>
                }

                <br/>
                <br/>
            </div>
                
        </div>
    )
}

export default EditProfilePage