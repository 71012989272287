import React, { useRef, useState } from 'react'
import './basicbookingform.styles.scss'
import ButtonComponent from '../Button/ButtonComponent'
import { design, namaste } from '../../assets/images'
import IconDropDown from '../IconDropDown'
import { PiBabyThin, PiCalendarBlankThin, PiClockThin, PiUserThin } from 'react-icons/pi'
import { bookGuide, getRoomName, GeneralSocket, ChatSocket } from '../../utils/service'
import { notify } from '../Toast'
import constants from '../../constants'

const BasicBookingForm = ({fullName, image, onClick, user1_id, user1_username, guideId, guideUsername, requestPersonalizedTourFunction}) => {
	const generalSocketRef = useRef()
	const chatSocketRef = useRef()

	function getNext14Days() {
		const options = [];
		const today = new Date();

		for (let i = 0; i < 14; i++) {
			const nextDay = new Date(today);
			nextDay.setDate(today.getDate() + i);

			const day = nextDay.getDate();
			const month = nextDay.toLocaleString('default', { month: 'short' });
			const labelText = `${day} ${month}`;
			const valueText = `${nextDay.getFullYear()}-${nextDay.getMonth()+1}-${nextDay.getDate()}`
			const entry = {'value':valueText, 'label':labelText}
			options.push(entry);
		}

		return options;
	}

	const [data, setData] = useState({
		"date": getNext14Days()[0].value,
		"time": "10:00:00",
		"adults" : 1,
		"childrens" : 1
	})

	const confirmHandler = () => {
		

		if(String(data.adults) === '0' && String(data.childrens) === '0'){
			return notify('please choose minimum 1 adult or children')
		}
		bookGuide({...data, guide:guideUsername}).then(res => {

			// for room name
			const roomName = getRoomName(user1_id, user1_username, guideId, guideUsername)
			// Message for guide in message
			const message = `Hey ${guideUsername}👋\nYou have new booking from ${String(user1_username)?.toLocaleUpperCase()}\n<b>Booking Details:</b>\nAdults : ${data.adults}\nChildrens : ${data.childrens}\nDate : ${data.date}\nTime: ${data.time} `

			if(requestPersonalizedTourFunction){
				requestPersonalizedTourFunction(res.data.basicFormId)
			}
			
			// two web sockets one for message and one for notification
			GeneralSocket(guideId, user1_username, generalSocketRef, message, constants.bookingAndNotification)
			ChatSocket(roomName, user1_username, message, chatSocketRef)
			

			// Notifications
			notify('Congrats ! soon guide will respond to your request')

		}).catch(err =>{
			notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
		})
	}

	return (
		<>
			<div className='card-information-container'>
				{/* guide image */}
				<img className='guide-image' src={image} alt='Guide Name' />
				<div>
					{/* namaste */}
					<img className='namaste' src={namaste} alt='namaste' />
					{/* name */}
					<h2 className='title-text'>I am {fullName}</h2>
				</div>

				{/* indiaFort image */}
				<p></p>
				<img className='india-fort' src={design} alt='IndiaFort' />
			</div>

			{/* Text and dropdown container*/}
			<div className='px-10'>
				<p className='suggestion-text'>Tell me your preferred date and group size</p>
			</div>

			{/* Dropdowns */}
			<div className='dropdown-container-pt'>
				<IconDropDown Icon={PiCalendarBlankThin}>
					<select value={data.date} onChange={(e)=>setData({...data, date:e.target.value})}>
						{getNext14Days().map(item => {
							return(
								<option key={item.value} value={item.value}>{item.label}</option>
							)
						})}
					</select>
				</IconDropDown>

				<IconDropDown Icon={PiClockThin}>
					<select value={data.time} onChange={(e)=>setData({...data, time:e.target.value})}>
						<option value='10:00:00'>10:00 am IST</option>
						<option value='11:00:00'>11:00 am IST</option>
						<option value='12:00:00'>12:00 pm IST</option>
						<option value='01:00:00'>01:00 pm IST</option>
						<option value='02:00:00'>02:00 pm IST</option>
						<option value='03:00:00'>03:00 pm IST</option>
						<option value='04:00:00'>04:00 pm IST</option>
						<option value='05:00:00'>05:00 pm IST</option>
						<option value='06:00:00'>06:00 pm IST</option>
						<option value='07:00:00'>07:00 pm IST</option>
					</select>
				</IconDropDown>

				<IconDropDown Icon={PiUserThin}>
					<select value={data.adults} onChange={(e)=>setData({...data, adults:e.target.value})}>
						<option value={0}>0 Adults</option>
						<option value={1}>1 Adult</option>
						<option value={2}>2 Adults</option>
						<option value={3}>3 Adults</option>
						<option value={4}>4 Adults</option>
						<option value={5}>5 Adults</option>
						<option value={6}>6 Adults</option>
						<option value={7}>7 Adults</option>
						<option value={8}>8 Adults</option>
						<option value={9}>9 Adults</option>
						<option value={10}>10 Adults</option>
						<option value={11}>11 Adults</option>
						<option value={12}>12 Adults</option>
						<option value={13}>13 Adults</option>
						<option value={14}>14 Adults</option>
						<option value={15}>15 Adults</option>
						<option value={16}>16 Adults</option>
						<option value={17}>17 Adults</option>
						<option value={18}>18 Adults</option>
						<option value={19}>19 Adults</option>
						<option value={20}>20 Adults</option>
					</select>
				</IconDropDown>

				<IconDropDown Icon={PiBabyThin}>
					<select value={data.childrens} onChange={(e)=>setData({...data, childrens:e.target.value})}>
						<option value={0}>0 Childrens</option>
						<option value={1}>1 Children</option>
						<option value={2}>2 Childrens</option>
						<option value={3}>3 Childrens</option>
						<option value={4}>4 Childrens</option>
						<option value={5}>5 Childrens</option>
						<option value={6}>6 Childrens</option>
						<option value={7}>7 Childrens</option>
						<option value={8}>8 Childrens</option>
						<option value={9}>9 Childrens</option>
						<option value={10}>10 Childrens</option>
						<option value={11}>11 Childrens</option>
						<option value={12}>12 Childrens</option>
						<option value={13}>13 Childrens</option>
						<option value={14}>14 Childrens</option>
						<option value={15}>15 Childrens</option>
						<option value={16}>16 Childrens</option>
						<option value={17}>17 Childrens</option>
						<option value={18}>18 Childrens</option>
						<option value={19}>19 Childrens</option>
						<option value={20}>20 Childrens</option>
					</select>
				</IconDropDown>
			</div>

			{/* Buttons */}
			<div className='d-flex items-center'>
				<ButtonComponent buttonTextColor='gray' wrapperContainerStyle={{width:'50%', padding:'13px', margin:'5px', backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)"}} title='Reset' />
				<ButtonComponent buttonId='basic-booking-form' wrapperContainerStyle={{width:'50%', padding:'13px', margin:'5px', backgroundImage:`url('${design}')`, backgroundRepeat:"no-repeat", backgroundPosition:"30px center", backgroundColor:"var(--primary)"}} onClick={()=>confirmHandler()} title='Confirm' />
			</div>

			{onClick &&
					<ButtonComponent title='Cancel' wrapperContainerStyle={{marginTop:5}} onClick={onClick}/>
			}
		</>
	)
}

export default BasicBookingForm
