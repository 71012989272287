import React from 'react'
import './card.styles.scss'
import RazorpayComponent from '../RazorpayComponent'

const Card = ({index, title, desc, price, selected, setSelected}) => {
  return (
    <div onClick={()=>{setSelected(index)}} className={`card-container ${selected ? 'selected' : ''}`}>
      <div className='d-flex space-between items-center'>
        <h1>{title}</h1>
        <p>₹{price}</p>
      </div>
      <p>{desc}</p>
      <div className='d-flex flex-end'>
        <div className='circle'/>
      </div>
      {selected && <RazorpayComponent setSelected={setSelected} id={index+1} />}
    </div>
  )
}

export default Card