import React, { useContext } from 'react'
import './signuppage.styles.scss'
import { Signup } from '../../assets/images'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent, DividerComponent, IconInput, IconInputDropDown } from '../../components'
import { BsCalendar, BsGenderAmbiguous, BsPerson } from 'react-icons/bs'
import { Google } from '../../assets/icons'
import { StateContext } from '../../context/stateContext'

const GENDER_DATA = [
    {id:1, value:'None', text:"Select Gender"},
    {id:2, value:'Male', text:"Male"},
    {id:3, value:'Female', text:"Female"},
    {id:4, value:'Not Specified', text:"Prefer not to specify"},
]

const SignupPage = () => {
  const {isTourist, userForm, setUserForm} = useContext(StateContext)
  const navigate = useNavigate()

  return (
    <div className='signup-container'>
        <div className='text-wrapper'>
            <h1>Create Account as {isTourist ? 'Tourist' : 'Guide'}</h1>
            <p>Being your first adventure exploring captivating places.</p>
        </div>
        <div className='input-container-sp'>
            <IconInput value={userForm?.fullName} onChange={(e)=>setUserForm({...userForm, fullName:e.target.value})} Icon={BsPerson} iconSize={25} placeholder='Full Name' />
            <IconInput value={userForm?.dob} onChange={(e)=>setUserForm({...userForm, dob:e.target.value})} className='custom-input' type='date' hideLabel={true} Icon={BsCalendar} iconSize={25} placeholder='Date of Birth'/>
            <IconInputDropDown value={userForm?.gender} onChange={(e)=>setUserForm({...userForm, gender:e.target.value})} Icon={BsGenderAmbiguous} selectData={GENDER_DATA}/>
            <ButtonComponent  title='Create Account' wrapperContainerStyle={{marginTop:30}} onClick={()=>navigate('/Verification-wizard/contact-verification')}/>
            <DividerComponent text='OR'/>
            <ButtonComponent title='Sign Up with Google' img={Google} buttonTextColor='black' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right bottom, whitesmoke  41%, whitesmoke  100%)", position:"relative"}}/>
        </div>
        <p className='account-text'>Already have an account ? <span onClick={()=>navigate('/Login')}>Login.</span></p>
        <img className='india-gate' src={Signup} alt='India gate with clouds and birds' />
    </div>
  )
}

export default SignupPage