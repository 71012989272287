import React, { useState } from 'react'
import { BsPerson, BsStar } from 'react-icons/bs'
import './imagecard.styles.scss'
import { useNavigate } from 'react-router-dom'
import { PiBookmarkSimpleThin , PiBookmarkSimpleFill, PiCircleFill, PiTrendUpBold } from 'react-icons/pi'
import { lefTop } from '../../assets/images'
import { storeIntoRecentSearch } from '../../utils/service'

function ImageCard({_id, image, isNew, placeName, guidesAvailable, visitorsCount, eventNotifier, setEventNotifier, redirectText, bookmarked, bookmarkFunction, guideName, guideAge, activeStatus, guidesChipsData, metaRedirectText, searchText, comingFromSearch=false, ...props}) {
	const navigate = useNavigate()
	const [isBookmarked, setIsBookmarked] = useState(bookmarked)

	const handleClick = () => {
		if(comingFromSearch && searchText.length > 0){
			storeIntoRecentSearch(_id, searchText)
		}
		navigate(redirectText, { state: { ...metaRedirectText } });
	};
	const bookMarkStatusHandler = () => {
		bookmarkFunction(_id);
		setIsBookmarked(!isBookmarked)
		if(eventNotifier !== undefined){
			setEventNotifier(!eventNotifier)
		}
	}

	return (
		<div className='image-card-container' style={{backgroundImage:`url(${image})`}} {...props} >
			
			{isNew &&
				<div className='corner-cap'>
					<img src={lefTop} alt='' />
					<p className='label'>New</p>
				</div>
			}

			{/* Bookmark icons */}
			{isBookmarked ? 
				<PiBookmarkSimpleFill onClick={()=>bookMarkStatusHandler()} className='cursor-pointer bookmark-place filled'/>
				:
				<PiBookmarkSimpleThin onClick={()=>bookMarkStatusHandler()} className='cursor-pointer bookmark-place' />
			}

			{/* Guide name and guide age : meta info */}
			<div className={`${guideName && 'px-10'}`}>
				{guideName &&
					<p onClick={()=>handleClick()} className={`d-flex guide-name`}>{guideName}</p>
				}
				{guideAge &&
				<div className='age-and-status-container d-flex items-center'>
					<p className='age-text'>Age : {guideAge}</p>
					<div className='d-flex items-center'>
					<PiCircleFill color={activeStatus ? 'green' : 'gray'} />
					<p className={`active-text`}>{activeStatus ? 'Active' : 'InActive'}</p>
					</div>
				</div>
				}
			</div>

			{/* chips */}
			{guidesChipsData?.length > 0 &&
				<>
					<div className='chips-container'>
						{guidesChipsData.slice(0, 2).map(item => {
						return(
							<p key={item} className='chips-text'>{item}</p>
							) 
						})}
					</div>
				</>
			}

			{/* place name */}
			<p onClick={()=>handleClick()} className={`${visitorsCount ? 'place-name' : "custom-text-class"}`}>{placeName}</p>

			{/* meta information about guide and places */}
			<div onClick={()=>handleClick()} className='meta-information-container'>
				{visitorsCount &&
					<>
						<BsStar /> 
						<div className='d-flex items-center space-between w-100'>
							<p>{visitorsCount} + Visitors </p>
							{/* Trending Icon */}
							<PiTrendUpBold color='green' size={22} style={{marginBottom:-5, zIndex:100}}/>
						</div>


					</>
				}
				{guidesAvailable &&
				<>
					<BsPerson />
					<p className='guides-text'>{guidesAvailable} + Guides available </p>
				</>
				}
			</div>


		</div>
	)
}

export default ImageCard
