import React, { useContext, useEffect, useRef, useState } from 'react'
import './chathomepage.styles.scss'
import { BackButton, ChatList } from '../../components'
import { PiMagnifyingGlass } from 'react-icons/pi'
import { animated, useSpring } from '@react-spring/web'
import { StateContext } from '../../context/stateContext'


const ChatHomePage = () => {
    const {chatHomePageData, chatListHandler, chatsList, setChatsList} = useContext(StateContext)
    const [selectedTab, setSeletedTab] = useState('All')
    const [showInput, setShowInput] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(undefined)
    const apiFired = useRef()
    const drawerAnimation = useSpring({
        transform: showInput ? 'translateX(0%)' : 'translateX(100%)',
        config: { tension: 100, friction: 15 }, // Spring-like configuration
    });
  

    useEffect(()=>{
        if(searchText.length > 0){
            setLoading(true)
            if(timer){
                clearTimeout(timer)
            }
            let timeId = setTimeout(()=>{
                const filterResults = chatHomePageData.filter(item => item?.name?.toLocaleLowerCase().includes(searchText?.toLocaleLowerCase()))
                setChatsList(filterResults)
                setLoading(false)
            }, 1000)
            setTimer(timeId)
        }
        else{
            setChatsList(chatHomePageData)
        }
    // eslint-disable-next-line 
    },[searchText])

    useEffect(()=>{
        if(!apiFired.current){
            chatListHandler()
            apiFired.current = true
        }
        // eslint-disable-next-line
    },[])

    return (
        <div className='px-10'>
            <div className='chathomepage-container d-flex space-between items-center my-20 px-10'>
                <BackButton size={24} style={{marginLeft:-15}}/>
                <h4 className={`text-title ${showInput ? 'mute' : ''}`}>Messages</h4>
                <animated.div className={`${showInput ? '' : "mute"}`} style={drawerAnimation}>
                    <input value={searchText} onChange={(e)=>setSearchText(e.target.value)} style={{width:'100%', height:30}}/>
                </animated.div>
                <PiMagnifyingGlass size={30} onClick={()=>setShowInput(!showInput)}/>
            </div>

            <p className='px-10'>Recent Chats</p>
            
            <div className='px-10 d-flex my-20 chips-container'>
                <p onClick={()=>setSeletedTab('All')} className={`${selectedTab === 'All' ? 'active' : ""}`}>All</p>
                <p onClick={()=>setSeletedTab('Unread')} className={`${selectedTab === 'Unread' ? 'active' : ""}`}>Unread</p>
                <p onClick={()=>setSeletedTab('Read')} className={`${selectedTab === 'Read' ? 'active' : ""}`}>Read</p>
            </div>

            {loading ?
                // show loading 
                <p>Loading...</p>
                :
                <>
                    {chatsList.map(({id, participantDetails, lastMessage, isActive})=>{
                        return (
                            <ChatList
                                key={id}
                                isActive={isActive}
                                userId={participantDetails?.id}
                                image={participantDetails?.profileImage}
                                username={participantDetails?.username}
                                fullName={participantDetails?.fullName}
                                message={lastMessage?.message}
                                lastMessageFrom={lastMessage?.sender}
                                isRead={lastMessage?.isRead}
                                unreadCount={lastMessage?.unreadCount}
                                timestamp={lastMessage?.timestamp}
                            />
                        )
                    })}
                </>
            }
        </div>
  )
}

export default ChatHomePage