import React from 'react'
import { BackButton, Toggle } from '../../components'
import './changetheme.styles.scss'
import { PiDresserThin,  PiFanThin } from 'react-icons/pi'

const ChangeThemePage = () => {
  return (
    <div>
        <BackButton size={24} text='Change theme'/>

        <div className='icon-text-container d-flex space-between items-center'>
            <div className='d-flex items-center'>
                <PiFanThin />
                <p>Profile Theme</p>
            </div>
            <Toggle />
        </div>
            
        <div className='icon-text-container d-flex space-between items-center'>
            <div className='d-flex items-center'>
                <PiDresserThin />
                <p>Message Theme</p>
            </div>
        </div>

            
    </div>
  )
}

export default ChangeThemePage